import React from 'react';
import {Link} from "react-router-dom";

export default function Button(props)
{
	return (
	<div className="page-container pb-2">
		<div className="px-3">
			{
				(function ()
				{
					if (props.url)
						return <Link to={props.url}>
							<button id={props.id} className="btn btn-primary"
							        onClick={props.onClick}>{props.name}</button>
						</Link>;

					return <button id={props.id} className="btn btn-primary" data-toggle="modal"
					               data-target=".modal"
					               onClick={props.onClick}>{props.name}</button>
				})()
			}
		</div>
	</div>
	);
};