import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle.min';

import {MainContext} from "../contexts/MainContext";

export default function Header()
{
	const {user, apiUrl, setMainContextState} = useContext(MainContext);
	const [state, setState] = useState({
		revoking: false
	});

	const logout = () =>
	{
		if (user !== null)
		{
			window.localStorage.removeItem('user');
			setMainContextState({
				user: null
			})
		}
	};

	const revokePermission = (e) =>
	{
		if (!state.revoking)
		{
			setState({
				...state,
				revoking: true
			});

			fetch(apiUrl + "/facebook/revoke", {
				method: `POST`,
				headers: {
					authorization: user.token
				}
			}).then(response => response.json())    // one extra step
			.then(data =>
			{
				if (data.success)
				{
					window.localStorage.removeItem('user');
					setMainContextState({
						user: null
					})
				}
			}).catch((err) =>
			{
				console.log('錯誤:', err);
			})
		}
	};

	return (
	<div id="header" className="page-header">
		<div className="navbar navbar-expand-lg">

			<Link to="/" className="navbar-brand">
				<span className="hidden-folded text-highlight header-logo mr-0">
					<img src="https://assets.facebookautoreply.com/logo.png"/>
				</span>
				<h4 className="hidden-folded text-highlight header-title pl-2 weight-900">Facebook Page Auto Reply System</h4>
			</Link>

			<ul className="nav navbar-menu order-1 order-lg-2">
				<li className="nav-item dropdown">
					<a href="https://admin.facebookautoreply.com/policy">
						<span>Privacy Policy</span>
					</a>
				</li>
				<li className="nav-item dropdown">
					<a href="#" data-toggle="dropdown"
					   className="nav-link d-flex align-items-center px-2 text-color">
				        <span className="avatar w-32"><i width="18" height="18"
				                                         data-feather='user'></i></span>Admin
					</a>

					<div className="dropdown-menu dropdown-menu-right mt-3 animate fadeIn">
						<a className="dropdown-item" href="javascript:void(0)" onClick={logout}>
							<span>Logout</span>
						</a>
						<a className="dropdown-item" href="javascript:void(0)"
						   onClick={revokePermission}>
							<span>Revoke Facebook Permissions</span>
						</a>
					</div>
				</li>
			</ul>
		</div>
	</div>
	);
};