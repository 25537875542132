import React, {createContext, useState, useEffect, useContext} from 'react';
import {useParams} from "react-router-dom";

import {PageContext} from "./PageContext";
import {FormContext} from "./FormContext";
import {MainContext} from "./MainContext";


export const CampaignCreateContext = createContext();

export default function CampaignCreateContextProvider(props)
{
	const {setFormContextState} = useContext(FormContext);
	const {user, setMainContextState} = useContext(MainContext);
	const {setPageContextState} = useContext(PageContext);

	const {id} = useParams();

	const [state, setState] = useState({
		page: id,
		init: false,
		scheduledPosts: false,
	});

	const setCampaignCreateContextState = (data) =>
	{
		for (var prop in data)
			state[prop] = data[prop];

		setState({
			...state
		});
	};

	useEffect(() =>
	{
		console.log("========== THIS IS CAMPAIGN CREATE CONTEXT ==========");

		if (user)
		{
			if (!state.init)
			{
				setState({
					...state,
					init: true
				});

				setPageContextState({
					header: "Create Campaign",

					subtitle: "Create Auto Reply Campaign For Facebook Post",

					currentPageType:'Form',

					currentPage: 'campaign',

					id: id,

					tableOptions: {},
					tableColumns: [],
					tableData: [],
				});

				setMainContextState({
					loading: false
				})

				setFormContextState({
					dateLoaded: true,
				})
			}
		}

	}, [state.init]);

	return (
	<CampaignCreateContext.Provider
	value={{...state, setCampaignCreateContextState}}>
		{props.children}
	</CampaignCreateContext.Provider>
	);
}
