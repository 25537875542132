import React, {createContext, useState} from 'react';

export const PageContext = createContext();

export default function PageContextProvider(props)
{
    const [state, setState] = useState({
        header: "Testing Header",
        subtitle: "Testing Subtitle",
        tableOptions: {},
        tableColumns: [],
        tableData: [],
        currentPageType: null,
        currentPage: null,
        createButtonName: null,
        urlPath: null,
        detail: null,
        id: null,
        isInit: false,
    });

    const setPageContextState = (data) =>
    {
        for (var prop in data)
            state[prop] = data[prop];

        setState({
            ...state
        });
    };

    return (
    <PageContext.Provider value={{...state, setPageContextState}}>
        {props.children}
    </PageContext.Provider>
    );
}
