import React, {useContext} from 'react';
import TinyMCEEditor from "./TinyMCEEditor";
import {FormContext} from "../contexts/FormContext";

export default function TextTemplate(props)
{
	const {data} = useContext(FormContext);

	const onEditorChange = (content) =>
	{
		if (props.fallback)
		{
			data.fallbackPM.content = {text: content};
		}
		else if (props.type === 'pm')
		{
			data.keywords[props.parent].replies[props.index].pm[props.type].content = {
				text: content
			};
		}
		else
		{
			data.keywords[props.parent].pm[props.type].content = {
				text: content
			};
		}

		return false;
	};

	const getEditorContent = () =>
	{
		let content;

		if (props.fallback)
			content = data.fallbackPM.content;
		else if (props.type === 'pm')
			content = data.keywords[props.parent].replies[props.index].pm[props.type].content;
		else
			content = data.keywords[props.parent].pm[props.type].content;

		return (content === null) ? null : content.text;
	};

	return (
	<>
		<div className="form-group label">
			Text
		</div>
		<div className="form-group">
			<TinyMCEEditor id={props.id} onChange={onEditorChange} content={getEditorContent()}/>
		</div>
	</>
	);
}