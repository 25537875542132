import React, {useContext} from 'react';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import {Redirect} from "react-router-dom";
import {Accordion} from "react-bootstrap";

import {FormContext} from "../contexts/FormContext";

import PageHeader from "../components/PageHeader";
import Modal from "../components/Modal";
import CommentReply from "../components/CommentReply";
import Keyword from "../components/Keyword";
import PrivateReply from "../components/PrivateReply";
import Error from "../components/Error";

export default function PolicyContainer(props)
{
    return <div class="p-4 text-light" dangerouslySetInnerHTML={{__html: `<h1 style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;line-height:107%;font-size:21px;font-family:"Calibri Light","sans-serif";color:#2E74B5;font-weight:normal;'><span style="font-family:Roboto;color:#f2f2f2;">Privacy policy</span></h1>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:.0001pt;line-height:107%;margin:0cm;'><span style="font-family:Roboto;color:#f2f2f2;">We respect your privacy and are committed to protecting it through our compliance with this privacy policy (&ldquo;Policy&rdquo;). This Policy describes the types of information we may collect from you or that you may provide (&ldquo;Personal Information&rdquo;) on the&nbsp;</span><a href="https://editor.facebookpageanalytics.com/" target="_blank"><span style="font-family:Roboto;color:#f2f2f2;border:none windowtext 1.0pt;padding:0cm;">editor.facebookpageanalytics.com</span></a><span style="font-family:Roboto;color:#f2f2f2;">&nbsp;website (&ldquo;Website&rdquo; or &ldquo;Service&rdquo;) and any of its related products and services (collectively, &ldquo;Services&rdquo;), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:.0001pt;line-height:107%;margin:0cm;'><span style="font-family:Roboto;color:#f2f2f2;">&nbsp;</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">This Policy is a legally binding agreement between you (&ldquo;User&rdquo;, &ldquo;you&rdquo; or &ldquo;your&rdquo;) and adbeyond (group) limited (doing business as &ldquo;GuruOnline&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms &ldquo;User&rdquo;, &ldquo;you&rdquo; or &ldquo;your&rdquo; shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#0070C0;">Use of data or information</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";margin:0cm;margin-bottom:.0001pt;'><span style="font-size:15px;font-family:Roboto;color:#0070C0;">The purposes for which information may be used by us in and outside Hong Kong include:</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";margin:0cm;margin-bottom:.0001pt;'><span style="font-size:15px;font-family:Roboto;color:#0070C0;">(a) processing applications with Facebook post comment with user name, entry and comment time for an automatically post comment and inbox reply</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";margin:0cm;margin-bottom:.0001pt;'><span style="font-size:15px;font-family:Roboto;color:#0070C0;">(b) providing services and information offered through the Site</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";margin:0cm;margin-bottom:.0001pt;'><span style="font-size:15px;font-family:Roboto;color:#0070C0;">(c) complying with laws, regulations or codes of practice in or outside Hong Kong</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";margin:0cm;margin-bottom:.0001pt;'><span style="font-size:15px;font-family:Roboto;color:#0070C0;">(e) other purposes directly relating to the activities identified above</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";margin:0cm;margin-bottom:.0001pt;'><span style="font-size:15px;font-family:Roboto;color:#0070C0;">(f) prevention or detection of crime, and protection of the security and safety of our events</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";margin:0cm;margin-bottom:.0001pt;'><span style="font-size:15px;font-family:Roboto;color:#0070C0;">(h) verification of identity in events organised by us where it is necessary to verify your identity</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman","serif";margin:0cm;margin-bottom:.0001pt;'><span style="font-size:15px;font-family:Roboto;color:#0070C0;">We may conduct direct marketing via fax, email, direct mail, telephone and other means of communication whether now or hereafter known, or send e-newsletters to you. You may choose not to receive promotional materials, by emailing us&nbsp;info@guruonline.com.hk, and we will cease to do so, without charge. If applicable law requires that we receive your explicit consent before we send you certain types of marketing communications, we will only send you those types of communications after receiving your explicit consent.</span></p>
<p><span style="font-size:15px;line-height:107%;font-family:Roboto;color:#0070C0;"><br>&nbsp;</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#0070C0;">Data Deletion</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#0070C0;">You have the right under this Privacy Policy, and by law if You are with or within the EU.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#0070C0;">You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request by contacting ernestyeung@guruonline.com.hk, We will try our best to respond to You as soon as possible.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#0070C0;">You have the right to request the deletion of Your Personal Data. Once We receive and confirm Your request, We will delete all of your data or information from our records, unless an exception applies.&nbsp;</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Collection of personal information</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">You can access and use the Website and Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the features offered on the Website, you may be asked to provide certain Personal Information (for example, your name and e-mail address).</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We receive and store any information you knowingly provide to us when you create an account, make a purchase, or fill any forms on the Website. When required, this information may include the following:</span></p>
<ul style="list-style-type: disc;margin-left:1.25px;">
    <li><span style="font-family:Roboto;color:#f2f2f2;">Account details (such as user name, unique user ID, password, etc)</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Contact information (such as email address, phone number, etc)</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Basic personal information (such as name, country of residence, etc)</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Payment information (such as credit card details, bank details, etc)</span></li>
</ul>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Some of the information we collect is directly from you via the Website and Services. However, we may also collect Personal Information about you from other sources such as public databases, social media platforms, third-party data providers, and our joint marketing partners. Personal Information we collect from other sources may include demographic information, such as age and gender, device information, such as IP addresses, location, such as city and state, and online behavioral data, such as information about your use of social media websites, page view information and search results and links.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the features on the Website. Users who are uncertain about what information is mandatory are welcome to contact us.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Privacy of children</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We recognize the need to provide further privacy protections with respect to Personal Information we may collect from children under the age of 13 and take many special precautions to protect their privacy. We do not require any Personal Information from children at any time. If you have reason to believe that a child under the age of 13 has provided Personal Information to us through the Website and Services, please contact us to request that we delete that child&rsquo;s Personal Information from our Services.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We encourage parents and legal guardians to monitor their children&rsquo;s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Website and Services without their permission. We also ask that all parents and legal guardians overseeing the care of children take the necessary precautions to ensure that their children are instructed to never give out Personal Information when online without their permission. We believe parents and legal guardians should be involved in the online activities of their children and suggest that parents do their best to provide their children with a safe and friendly online environment.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Use and processing of collected information</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We act as a data controller and a data processor when handling Personal Information, unless we have entered into a data processing agreement with you in which case you would be the data controller and we would be the data processor.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Our role may also differ depending on the specific situation involving Personal Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that is necessary to ensure your access and use of the Website and Services. In such instances, we are a data controller because we determine the purposes and means of the processing of Personal Information.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We act in the capacity of a data processor in situations when you submit Personal Information through the Website and Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">In order to make the Website and Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used for the following purposes:</span></p>
<ul style="list-style-type: disc;margin-left:1.25px;">
    <li><span style="font-family:Roboto;color:#f2f2f2;">Create and manage user accounts</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Fulfill and manage orders</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Deliver products or services</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Improve products and services</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Send administrative information</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Send product and service updates</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Respond to inquiries and offer support</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Request user feedback</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Improve user experience</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Deliver targeted advertising</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Respond to legal requests and prevent harm</span></li>
    <li><span style="font-family:Roboto;color:#f2f2f2;">Run and operate the Website and Services<br>&nbsp;<br>&nbsp;</span></li>
</ul>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Processing your Personal Information depends on how you interact with the Website and Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Payment processing</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">In case of Services requiring payment, you may need to provide your credit card details or other payment account information, which will be used solely for processing payments. We use third-party payment processors (&ldquo;Payment Processors&rdquo;) to assist us in processing your payment information securely.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Payment Processors adhere to the latest security standards as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. Sensitive and private data exchange happens over a SSL secured communication channel and is encrypted and protected with digital signatures, and the Website and Services are also in compliance with strict vulnerability standards in order to create as secure of an environment as possible for Users. We will share payment data with the Payment Processors only to the extent necessary for the purposes of processing your payments, refunding such payments, and dealing with complaints and queries related to such payments and refunds.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Please note that the Payment Processors may collect some Personal Information from you, which allows them to process your payments (e.g., your email address, address, credit card details, and bank account number) and handle all the steps in the payment process through their systems, including data collection and data processing. Where necessary for processing future or recurring payments and subject to your prior consent, your financial information will be stored in encrypted form on secure servers of our Payment Processors. The Payment Processors&rsquo; use of your Personal Information is governed by their respective privacy policies which may or may not contain privacy protections as protective as this Policy. We suggest that you review their respective privacy policies.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Managing information</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">You are able to delete certain Personal Information we have about you. The Personal Information you can delete may change as the Website and Services change. When you delete Personal Information, however, we may maintain a copy of the unrevised Personal Information in our records for the duration necessary to comply with our obligations to our affiliates and partners, and for the purposes described below. If you would like to delete your Personal Information or permanently delete your account, you can do so on the settings page of your account on the Website.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Disclosure of information</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Depending on the requested Services or as necessary to complete any transaction or provide any Service you have requested, we may share your information with our affiliates, contracted companies, and service providers (collectively, &ldquo;Service Providers&rdquo;) we rely upon to assist in the operation of the Website and Services available to you and whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. We will not share any personally identifiable information with third parties and will not share any information with unaffiliated third parties.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Service Providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. Service Providers are given the information they need only in order to perform their designated functions, and we do not authorize them to use or disclose any of the provided information for their own marketing or other purposes.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We may also disclose any Personal Information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Retention of information</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We will retain and use your Personal Information for the period necessary to comply with our legal obligations, as long as your user account remains active, to enforce our agreements, resolve disputes, and unless a longer retention period is required or permitted by law.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Cookies</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:.0001pt;line-height:107%;margin:0cm;'><span style="font-family:Roboto;color:#f2f2f2;">Our Website and Services use &ldquo;cookies&rdquo; to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. You may learn more about cookies and how they work in&nbsp;</span><a href="https://www.websitepolicies.com/blog/cookies" target="_blank"><span style="font-family:Roboto;color:#f2f2f2;border:none windowtext 1.0pt;padding:0cm;">this guide</span></a><span style="font-family:Roboto;color:#f2f2f2;">.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We may use cookies to collect, store, and track information for security and personalization, and for statistical purposes. Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept cookies by default, but you can modify your browser settings to decline cookies if you prefer.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Data analytics</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Our Website and Services may use third-party analytics tools that use cookies, web beacons, or other similar information-gathering technologies to collect standard internet activity and usage information. The information gathered is used to compile statistical reports on User activity such as how often Users visit our Website and Services, what pages they visit and for how long, etc. We use the information obtained from these analytics tools to monitor the performance and improve our Website and Services. We do not use third-party analytics tools to track or to collect any personally identifiable information of our Users and we will not associate any information gathered from the statistical reports with any individual User.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Do Not Track signals</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, the Website and Services are not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your Personal Information.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Advertisements</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We may permit certain third-party companies to help us tailor advertising that we think may be of interest to Users and to collect and use other data about User activities on the Website. These companies may deliver ads that might place cookies and otherwise track User behavior.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Email marketing</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We offer electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section or for the purposes of utilizing a third-party provider to send such emails. We will maintain the information sent via e-mail in accordance with applicable laws and regulations.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who the e-mail is from and provide clear information on how to contact the sender. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or by contacting us. However, you will continue to receive essential transactional emails.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Links to other resources</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">The Website and Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Website and Services and to read the privacy statements of each and every resource that may collect Personal Information.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Information security</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Website and Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">As the security of Personal Information depends in part on the security of the device you use to communicate with us and the security you use to protect your credentials, please take appropriate measures to protect this information.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Data breach</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">In the event we become aware that the security of the Website and Services has been compromised or Users&rsquo; Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the User as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Changes and amendments</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We reserve the right to modify this Policy or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page, post a notification on the main page of the Website. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Acceptance of this policy</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.</span></p>
<h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman","serif";font-weight:bold;margin-top:0cm;margin-bottom:11.25pt;'><span style="font-family:Roboto;color:#f2f2f2;">Contacting us</span></h2>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">Ernestyeung@guruonline.com.hk</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:11.25pt;line-height:107%;'><span style="font-family:Roboto;color:#f2f2f2;">We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws.</span></p>
<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri","sans-serif";margin-top:0cm;margin-bottom:.0001pt;line-height:107%;margin:0cm;'><span style="font-family:Roboto;color:#f2f2f2;">This document was last updated on September 2, 2021</span></p>`}}/>
}