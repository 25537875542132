import React, {useContext} from 'react';
import {Accordion, Button, Card} from "react-bootstrap";

import {FormContext} from "../contexts/FormContext";
import Reply from "./Reply";
import PrivateReply from "./PrivateReply";
import CommentReply from "./CommentReply";


export default function Keyword(props)
{
	const {data, setFormContextState} = useContext(FormContext);

	const onKeywordChange = (e) =>
	{
		data.keywords[props.index].keyword = e.target.value;

		setFormContextState({
			data: data,
		});

		return false;
	};

	const toggleRandom = (e) =>
	{

		if (data.keywords[props.index].random)
			data.keywords[props.index].linkCMPM = false;

		data.keywords[props.index].random = !data.keywords[props.index].random;

		setFormContextState({
			data: data,
		});

		return false;
	};

	const toggleLinkCMPM = (e) =>
	{
		data.keywords[props.index].linkCMPM = !data.keywords[props.index].linkCMPM;

		setFormContextState({
			data: data,
		});

		return false;
	};

	const toggleAllowDuplicate = (e) =>
	{
		data.keywords[props.index].allowDuplicate = !data.keywords[props.index].allowDuplicate;

		setFormContextState({
			data: data,
		});

		return false;
	};

	const addReply = (e) =>
	{
		const reply = {
			comment: {
				comment: {text: '', image: null},
				quota: 0,
				id: null,
			},
			pm: {
				pm: {type: 'text', content: null, quickReplies: []},
				quota: 0,
				cache: {
					pm: {type: 'text', content: null},
				},
				id: null,
			},
			haveComment: true,
			havePM: false,
		};

		data.keywords[props.index].replies.push(JSON.parse(JSON.stringify(reply)));

		setFormContextState({
			data: data,
		});

		return false;
	};

	const removeKeyword = (e) =>
	{
		data.keywords.splice(e.target.dataset.index, 1);

		setFormContextState({
			data: data,
		})
	};

	return (
	<Card>
		<Accordion.Toggle as={Button} variant="link" eventKey={props.index}>
			<Card.Header>
				<h6 className="mb-0 d-inline-block weight-700">
					Keyword #{props.index + 1}
				</h6>
			</Card.Header>
			{
				(data.keywords.length > 1) && <div className="close d-inline-block pb-2 pt-1 pl-2 pr-2" onClick={removeKeyword}>
					<span aria-hidden="true" data-index={props.index}>&times;</span>
				</div>
			}
		</Accordion.Toggle>
		<Accordion.Collapse eventKey={props.index} data-index={props.index}>
			<Card.Body>
				<div className="form-group">
					<label className="mb-3"> Keyword(s) </label>
					<input type="text"
					       className="form-control keyword"
					       name="keywords[]"
					       aria-describedby="keyword"
					       placeholder="Please Enter Keyword(s)"
					       onChange={onKeywordChange}
					       value={data.keywords[props.index].keyword}
					       required/>
					<small className="form-text text-muted"> You Can Seperate Several keywords by
						comma E.g. boy,girl </small>
				</div>

				<div className="form-group">
					<label htmlFor=""> Reply(s) </label>
				</div>


				<Accordion defaultActiveKey="0" id={`replyAccordion` + props.index}
				           className="replyAccordion">
					{
						data.keywords[props.index].replies.map((item, i) => <Reply key={i}
						                                                           parent={props.index}
						                                                           index={i}/>)
					}
				</Accordion>

				<div className="form-group">
					<button type="button" className="btn btn-info" onClick={addReply}>Add Reply
					</button>
				</div>
				<hr/>
				<CommentReply title="No Quota Comment Reply" class="noQuota"
				              index={props.index} type="noQuota" fallback={false}/>
				<hr/>
				<PrivateReply title="No Quota Private Reply" parent={props.index} type="noQuota"
				              fallback={false}/>
				<hr/>

				<div className="form-group">
					<label htmlFor=""> Allow User Duplicate Reply?</label>
				</div>

				<div className="custom-control custom-switch mb-3">

					<input type="checkbox" className="custom-control-input"
					       id={`allowDuplicate-switch-keyword-` + props.index}
					       onChange={toggleAllowDuplicate}
					       checked={data.keywords[props.index].allowDuplicate}/>

					<label className="custom-control-label"
					       htmlFor={`allowDuplicate-switch-keyword-` + props.index}></label>
				</div>

				<hr/>

				{
					(!data.keywords[props.index].allowDuplicate) && <>
						<CommentReply title="Already Commented Reply" class="replied"
						              index={props.index} type="replied" fallback={false}/>
						<hr/>
						< PrivateReply title="Already Commented Private Reply" parent={props.index}
						               type="replied" fallback={false}/>
						<hr/>
					</>
				}

				<div className="form-group">
					<label htmlFor=""> Random?</label>
				</div>

				<div className="custom-control custom-switch mb-3">
					<input type="checkbox" className="custom-control-input"
					       id={`random-switch-keyword-` + props.index} onChange={toggleRandom}
					       checked={data.keywords[props.index].random}/>
					<label className="custom-control-label"
					       htmlFor={`random-switch-keyword-` + props.index}></label>
				</div>

				{
					(data.keywords[props.index].random) && <>
						<div className="form-group">
							<label htmlFor=""> Link Comment and Private Reply?</label>
						</div>

						<div className="custom-control custom-switch mb-3">
							<input type="checkbox" className="custom-control-input"
							       id={`linkPMCM-switch-keyword-` + props.index}
							       onChange={toggleLinkCMPM}
							       checked={data.keywords[props.index].linkCMPM}/>

							<label className="custom-control-label"
							       htmlFor={`linkPMCM-switch-keyword-` + props.index}></label>
						</div>
					</>
				}
			</Card.Body>
		</Accordion.Collapse>
	</Card>
	);
}