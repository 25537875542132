import React, {useContext} from 'react';
import PageHeader from "../components/PageHeader";
import Table from "../components/Table";
import Button from "../components/Button";
import Modal from "../components/Modal";
import {FacebookDataContext} from "../contexts/FacebookDataContext";
import {PageContext} from "../contexts/PageContext";
import {MainContext} from "../contexts/MainContext";

export default function ListContainer()
{
	const {apiUrl, user} = useContext(MainContext);
	const {fetchFacebookData} = useContext(FacebookDataContext);
	const {createButtonName, urlPath, detail, currentPage} = useContext(PageContext);

	const regeneratePageToken = () =>
	{
		if (window.confirm("Confirm To Regenerate All Tokens?"))
		{

			fetch(apiUrl + "/pages/regenerate", {
				headers: {
					authorization: user.token
				}
			}).then(response => response.json())    // one extra step
			.then(data =>
			{
				console.log("Finished Re-Generating Token");
				console.log(data);
			}).catch((err) =>
			{
				console.log('錯誤:', err);
			})
		}
	}

	return (
	<div id="content" className="flex">
		<PageHeader/>
		{
			(urlPath !== null) ? <Button id="addData" name={createButtonName} url={urlPath}/> :
			<Button id="addData" name={createButtonName} onClick={fetchFacebookData}/>
		}

		{
			(currentPage === 'page') &&
			<div className="page-container pb-2">
				<div className="px-3">
					<button className="btn btn-green" onClick={regeneratePageToken}> Regenerate All
						Page Token
					</button>
				</div>
			</div>
		}

		{
			(detail) &&
			<>
				<div className="page-hero page-container">
					<div className="p-3">
						{
							detail.map((item, i) => <div className="row py-3" key={i}>
								<div className="col-12">
									<h5
									className="font-weight-bold text-highlight detail-name">{item.name}</h5>
								</div>
								<div className="col-12">
									{item.value}
								</div>
							</div>)
						}
					</div>
				</div>

				<div className="page-hero page-container" id="page-hero">
					<div className="pt-3 px-3">
						<div className="page-title"><h3
						className="font-weight-bold text-highlight mb-0">Comments</h3></div>
					</div>
				</div>
			</>
		}

		<div className="page-container">
			<div className="padding">
				<div className="row">
					<Table/>
				</div>
			</div>
		</div>

		<Modal title="Add Facebook Page"/>
	</div>
	);
}