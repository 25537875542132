import React, {createContext, useState, useContext} from 'react';
import $ from 'jquery';
import SimpleBar from 'simplebar';

import {MainContext} from "./MainContext";


export const FacebookDataContext = createContext();

export default function FacebookDataContextProvider(props)
{
	const {apiUrl, user} = useContext(MainContext);

	const [state, setState] = useState({
		facebookData: [],
		tableOptions: {},
		tableColumns: [],
		fetched: false,
		before: null,
		after: null,
		modalOpen: false,
		loading: false,
		fetchUrl: null,
		dataId: null,
		type: null,
	});

	const setFacebookDataContextState = (data) =>
	{
		for (var prop in data)
		{
			console.log("This is PROP", prop);
			console.log("This is DATA", data[prop]);
			state[prop] = data[prop];
		}

		setState({
			...state
		});

		console.log("This is Facebook Data Context");
		console.log(state);
	};

	const fetchFacebookData = event =>
	{
		let body = {
			id: state.dataId,
			userId: state.userId,
			type: state.type
		};

		console.log("This is Fetch Facebook Data");
		console.log(state);

		if (event)
		{
			if (event.target.id === 'next')
				body.after = state.after;

			if (event.target.id === 'prev')
				body.before = state.before;
		}

		if (!state.loading)
		{
			state.modalOpen = true;
			state.loading = true;

			setState({
				...state
			});

			fetch(apiUrl + "/facebook" + state.fetchUrl, {
				method: 'POST', // or 'PUT'
				headers: {
					'Content-Type': 'application/json',
					authorization: user.token,
				},
				body: JSON.stringify(body)
			})
			.then(response => response.json())
			.then(data =>
			{
				console.log("This is Facebook Pages Data");
				console.log(data);

				state.loading = false;

				state.facebookData = data.data;

				console.log(data.data.length);

				if (data.data.length)
				{
					if (data.paging.hasOwnProperty('next'))
						state.after = data.paging.cursors.after;
					else
						state.after = null;

					if (data.paging.hasOwnProperty('previous'))
						state.before = data.paging.cursors.before;
					else
						state.before = null;
				}

				setState({
					...state
				});

				new SimpleBar($('.modal').find('.modal-body')[0]);

				console.log("This is Page State");
				console.log(state);

			}).catch((err) =>
			{
				console.log('錯誤:', err);
			})
		}
	};

	return (
	<FacebookDataContext.Provider
	value={{...state, fetchFacebookData, setFacebookDataContextState}}>
		{props.children}
	</FacebookDataContext.Provider>
	);
}
