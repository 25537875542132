import React, {useContext} from 'react';
import FacebookDataTable from "./FacebookDataTable";
import {FacebookDataContext} from "../contexts/FacebookDataContext";
import {PageContext} from "../contexts/PageContext";

export default function Modal(props)
{
	const {after, before, fetchFacebookData, modalOpen, loading, setFacebookDataContextState} = useContext(FacebookDataContext);
	const {currentPage} = useContext(PageContext);

	const closeModal = () =>
	{
		setFacebookDataContextState({
			modalOpen: false,
			before: null,
			after: null,
			facebookData: [],
		})
	};

	return (
	<div className="modal" tabIndex="-1" role="dialog" aria-labelledby="Add Page" aria-hidden="true"
	     style={{display: (modalOpen) ? 'block' : 'none'}}>
		<div className="modal-dialog modal-lg modal-dialog-centered"
		/*style="overflow-y: scroll; max-height:85%;  margin-top: 50px; margin-bottom:50px;"*/>
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title text-highlight weight-900">
						{props.title}</h5>
					<button type="button" className="close" data-dismiss="modal" aria-label="Close"
					        onClick={closeModal}>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
					{
						(function ()
						{
							if (currentPage === 'campaign')
							{
								return <div className="btn-group" role="group"
								            aria-label="Basic example">
									<button id="published" type="button"
									        className="btn btn-primary" onClick={props.onClick}>Published Post
									</button>
									<button id="scheduled" type="button"
									        className="btn btn-green" onClick={props.onClick}>Scheduled Post
									</button>
								</div>
							}
						})()
					}
					<FacebookDataTable/>
				</div>
				<div className="modal-footer">
					{
						(function ()
						{
							if (after)
							    return <button id="next" type="button" onClick={fetchFacebookData}
                                               className="btn btn-primary">Next</button>

                            if(before)
                                return <button id="prev" type="button" onClick={fetchFacebookData}
                                        className="btn btn-primary">Prev</button>
						})()
					}
				</div>
				<div className="modalLoader" style={{display: (loading) ? 'block' : 'none'}}>
					<div className="loadingIcon"><img
					src="https://assets.facebookautoreply.com/load.svg"/></div>
				</div>
			</div>
		</div>
{/*		<div className="modal-backdrop show" onClick={closeModal}></div>*/}
	</div>
	);
};