import React, {useContext} from 'react';
import {Redirect} from "react-router-dom";

import {MainContext} from "../contexts/MainContext";


export default function AuthComponent({component: Component, ...rest})
{
	const {user} = useContext(MainContext);

	return ((!user) ? <Redirect to='/login'/> : <Component/>);
}