import React, {useContext} from 'react';

import {FormContext} from "../contexts/FormContext";

export default function OneTimeTemplate(props)
{
	const {data, setFormContextState} = useContext(FormContext);

	const setTitle = (e) =>
	{
		const content = e.target.value;

		if (props.fallback)
			data.fallbackPM.content.title = content;
		else if (props.type === 'pm')
			data.keywords[props.parent].replies[props.index].pm[props.type].content.title = content;
		else
			data.keywords[props.parent].pm[props.type].content.title = content;

		setFormContextState({
			data: data
		});

		return false;
	};

	const setPayload = (e) =>
	{
		const content = e.target.value;

		if (props.fallback)
			data.fallbackPM.content.payload = content;
		else if (props.type === 'pm')
			data.keywords[props.parent].replies[props.index].pm[props.type].content.payload = content;
		else
			data.keywords[props.parent].pm[props.type].content.payload = content;

		setFormContextState({
			data: data
		});

		return false;
	};

	return (
	<>
		<div className="form-group label">Title</div>

		<div className="form-group">
			{
				(function ()
				{
					let title;

					if (props.fallback)
						title = data.fallbackPM.content.title;
					else if (props.type === 'pm')
						title = data.keywords[props.parent].replies[props.index].pm[props.type].content.title;
					else
						title = data.keywords[props.parent].pm[props.type].content.title;

					return <input type="text" className="form-control oneTimeTitle"
					              aria-describedby="keyword" onChange={setTitle}
					              placeholder="Enter Title" value={title}/>
				})()
			}
		</div>

		<div className="form-group label">Payload</div>

		<div className="form-group">
			{
				(function ()
				{
					let payload;

					if (props.fallback)
						payload = data.fallbackPM.content.payload;
					else if (props.type === 'pm')
						payload = data.keywords[props.parent].replies[props.index].pm[props.type].content.payload;
					else
						payload = data.keywords[props.parent].pm[props.type].content.payload;

					return <input type="text" className="form-control oneTimeTitle"
					              aria-describedby="keyword" onChange={setPayload}
					              placeholder="Enter Title" value={payload}/>
				})()
			}
		</div>

	</>)
}