import React, {useContext} from 'react';

import UrlButton from "./UrlButton";
import {FormContext} from "../contexts/FormContext";

export default function TemplateButton(props)
{
	const {data, setFormContextState} = useContext(FormContext);

	const setButtonType = (e) =>
	{
		const type = e.target.value;

		if (props.fallback)
		{
			if (typeof props.isElement !== 'undefined')
				data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].type = type;
			else
				data.fallbackPM.content.buttons[props.index].type = type;
		}
		else if (props.type === 'pm')
		{
			if (typeof props.isElement !== 'undefined')
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].type = type;
			else
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].type = type;
		}
		else
		{
			if (typeof props.isElement !== 'undefined')
				data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].type = type;
			else
				data.keywords[props.parent].pm[props.type].content.buttons[props.index].type = type;
		}


		const urlData = {
			url: '',
			ratio: 'full',
			isMessengerExtension: false
		};

		let buttonData = {
			payload: ''
		};

		if (type === 'url')
			buttonData = urlData;

		if (props.fallback)
		{
			if (typeof props.isElement !== 'undefined')
				data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].content = buttonData;
			else
				data.fallbackPM.content.buttons[props.index].content = buttonData;
		}
		else if (props.type === 'pm')
		{
			if (typeof props.isElement !== 'undefined')
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content = buttonData;
			else
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].content = buttonData;
			}
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content = buttonData;
				else
					data.keywords[props.parent].pm[props.type].content.buttons[props.index].content = buttonData;
			}

		setFormContextState({
			data: data
		});

		return false;
	};

	const setButtonTitle = (e) =>
	{
		if (props.fallback)
		{
			if (typeof props.isElement !== 'undefined')
				data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].title = e.target.value;
			else
				data.fallbackPM.content.buttons[props.index].title = e.target.value;
		}
		else if (props.type === 'pm')
		{
			if (typeof props.isElement !== 'undefined')
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].title = e.target.value;
			else
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].title = e.target.value;
		}
		else
		{
			if (typeof props.isElement !== 'undefined')
				data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].title = e.target.value;
			else
				data.keywords[props.parent].pm[props.type].content.buttons[props.index].title = e.target.value;
		}


		setFormContextState({
			data: data
		});

		return false;
	};

	const setButtonPayload = (e) =>
	{
		const payload = {
			payload: e.target.value
		};

		if (props.fallback)
		{
			if (typeof props.isElement !== 'undefined')
				data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].content = payload;
			else
				data.fallbackPM.content.buttons[props.index].content = payload;
		}
		else if (props.type === 'pm')
		{
			if (typeof props.isElement !== 'undefined')
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content = payload;
			else
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].content = payload;
		}
		else
		{
			if (typeof props.isElement !== 'undefined')
				data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content = payload;
			else
				data.keywords[props.parent].pm[props.type].content.buttons[props.index].content = payload;
		}

		setFormContextState({
			data: data
		});

		return false;
	};

	const removeButton = (e) =>
	{
		if (props.fallback)
		{
			if (typeof props.isElement !== 'undefined')
				data.fallbackPM.content.elements[props.bubbleParent].buttons.splice(props.index, 1);
			else
				data.fallbackPM.content.buttons.splice(props.index, 1);
		}
		else if (props.type === 'pm')
		{
			if (typeof props.isElement !== 'undefined')
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons.splice(props.index, 1);
			else
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons.splice(props.index, 1);
		}
		else
		{
			if (typeof props.isElement !== 'undefined')
				data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons.splice(props.index, 1);
			else
				data.keywords[props.parent].pm[props.type].content.buttons.splice(props.index, 1);
		}

		setFormContextState({
			data: data
		});

		return false;
	};

	return (
	<>
		<hr/>

		<h5 className="form-group">Button #{props.index + 1}</h5>

		<div className="form-group label">Type</div>

		<div className="form-group">
			{
				(function ()
				{
					let buttonType;

					if (props.fallback)
					{
						if (typeof props.isElement !== 'undefined')
							buttonType = data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].type;
						else
							buttonType = data.fallbackPM.content.buttons[props.index].type;
					}
					else if (props.type === 'pm')
					{
						if (typeof props.isElement !== 'undefined')
							buttonType = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].type;
						else
							buttonType = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].type;
					}
					else
					{
						if (typeof props.isElement !== 'undefined')
							buttonType = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].type;
						else
							buttonType = data.keywords[props.parent].pm[props.type].content.buttons[props.index].type;
					}

					return <select className="custom-select" name="isMultiple" value={buttonType}
					               onChange={setButtonType}>
						<option value="url">Url</option>
						<option value="postback">Postback</option>
					</select>
				})()
			}
		</div>

		<div className="form-group label">Title</div>
		<div className="form-group">
			{
				(function ()
				{
					let title;

					if (props.fallback)
					{
						if (typeof props.isElement !== 'undefined')
							title = data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].title;
						else
							title = data.fallbackPM.content.buttons[props.index].title;
					}
					else if (props.type === 'pm')
					{
						if (typeof props.isElement !== 'undefined')
							title = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].title;
						else
							title = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].title;
					}
					else
					{
						if (typeof props.isElement !== 'undefined')
							title = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].title;
						else
							title = data.keywords[props.parent].pm[props.type].content.buttons[props.index].title;
					}

					return <input type="text" className="form-control"
					              onChange={setButtonTitle}
					              value={title}
					              placeholder="Enter Button Title"/>
				})()
			}

		</div>

		{(function ()
		{
			let buttonType;
			let payload;

			if (props.fallback)
			{
				if (typeof props.isElement !== 'undefined')
				{
					buttonType = data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].type;
					payload = data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].content.payload;
				}
				else
				{
					buttonType = data.fallbackPM.content.buttons[props.index].type;
					payload = data.fallbackPM.content.buttons[props.index].content.payload;
				}
			}
			else if (props.type === 'pm')
			{
				if (typeof props.isElement !== 'undefined')
				{
					buttonType = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].type;
					payload = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.payload;
				}
				else
				{
					buttonType = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].type;
					payload = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].content.payload;
				}
			}
			else
			{
				if (typeof props.isElement !== 'undefined')
				{
					buttonType = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].type;
					payload = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.payload;
				}
				else
				{
					buttonType = data.keywords[props.parent].pm[props.type].content.buttons[props.index].type;
					payload = data.keywords[props.parent].pm[props.type].content.buttons[props.index].content.payload;
				}
			}

			if (buttonType === 'url')
			{
				return <UrlButton keywordParent={props.keywordParent} parent={props.parent}
				                  index={props.index}
				                  bubbleParent={props.bubbleParent}
				                  type={props.type}
				                  isElement={props.isElement}
				                  fallback={props.fallback}/>;
			}
			else if (buttonType === 'postback' || buttonType === 'one-time')
			{
				return (
				<>
					<div className="form-group label">Payload</div>
					<div className="form-group">
						<input type="text" className="form-control payload"
						       onChange={setButtonPayload}
						       value={payload}
						       placeholder="Payload"/>
					</div>
				</>)
			}

		})()}

		<div className="form-group">
			{
				(function ()
				{
					let render;

					if (props.fallback)
					{
						if (typeof props.isElement !== 'undefined')
							render = true;
						else
							render = (data.fallbackPM.content.buttons.length > 1);
					}
					else if (props.type === 'pm')
					{
						if (typeof props.isElement !== 'undefined')
							render = true;
						else
							render = (data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons.length > 1);
					}
					else
					{
						if (typeof props.isElement !== 'undefined')
							render = true;
						else
							render = (data.keywords[props.parent].pm[props.type].content.buttons.length > 1);
					}

					return (render) &&
					<button type="button" className="btn btn-red"
					        onClick={removeButton}>Remove</button>
				})()
			}
		</div>

	</>
	);
}