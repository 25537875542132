import React, {useContext, useEffect, useState} from 'react';
import Tabulator from 'tabulator-tables';

import {FacebookDataContext} from "../contexts/FacebookDataContext";

export default function FacebookDataTable() {
    const {facebookData,tableOptions,tableColumns} = useContext(FacebookDataContext);
    const [state, setState] = useState({
        el: React.createRef(),
        table: null,
    });

    const matchAny=(data, filterParams) =>
    {
        var checkVal=filterParams.value.toLowerCase();
        var tmpIsCols=Array.isArray(filterParams.columns);
        var tmpKeyList=data;

        if (tmpIsCols)
        {
            tmpKeyList={};
            for (var iPos in filterParams.columns)
            {
                var tmpCol=filterParams.columns[iPos];
                var tmpKey=tmpCol.field;
                if (tmpKey)
                {
                    tmpKeyList[tmpKey]=true;
                }
            }
        }

        var match=false;
        var key='';

        if (filterParams.startsWith === true)
        {
            for (key in tmpKeyList)
            {
                if (data[key].toLowerCase().indexOf(checkVal) === 0)
                {
                    match=true;
                    break;
                }
            }
        }
        else
        {
            for (key in tmpKeyList)
            {
                if (data[key] !== null)
                {
                    if (data[key].toLowerCase().indexOf(checkVal)> -1)
                    {
                        match=true;
                        break;
                    }
                }
            }
        }

        return match;
    }

    const search=(e) =>
    {
        console.log(e.target.value);
        state.table.setFilter(matchAny, {columns: tableColumns, value: e.target.value, startsWith: false});
    };

    useEffect(() => {
        state.table = new Tabulator(state.el, {
            layout: 'fitColumns',
            addRowPos: "bottom",
            data: facebookData, //link data to table
            reactiveData: false, //enable data reactivity
            columns: tableColumns,
             //define table columns
            validationFailed:function(cell, value, validators){
                return cell.cancelEdit();
            },
            rowFormatter: function (row)
            {
                row.getElement().classList.add("table-dark"); //mark rows with age greater than or equal to 18 as successful;
            },
            ...tableOptions
        });

        setState({
            ...state,
        });

    }, [facebookData]);

    return (
        <div className="tableContainer w-100">
            <div className="pb-3 d-flex justify-content-between">
                <div>
                    <div className="weight-500">Search:</div>
                    <input id="search" type="text" onKeyUp={search}/>
                </div>
            </div>
            <div className="table-dark" ref={el => (state.el = el)}/>
        </div>
    );
}