import React, {createContext, useState, useEffect, useContext} from 'react';
import {useParams} from "react-router-dom";
import moment from 'moment-timezone';

import {PageContext} from "./PageContext";
import {MainContext} from "./MainContext";
import {FacebookDataContext} from "./FacebookDataContext";


export const CampaignDetailContext = createContext();

export default function CampaignDetailContextProvider(props)
{
	const {apiUrl, user, setMainContextState} = useContext(MainContext);

	const {detail, setPageContextState} = useContext(PageContext);

	const {id} = useParams();

	const [state, setState] = useState({
		campaign: id,
		fetching: false,
		fetched: false,
		comments: [],
	});

	useEffect(() =>
	{
		console.log("========== THIS IS CAMPAIGN DETAIL CONTEXT ==========");

		if (user)
		{
			setPageContextState({
				tableData: state.comments
			});

			if (!state.fetched && !state.fetching)
			{
				setPageContextState({
					header: "Campaign Detail",

					subtitle: "Detail For Facebook Post Campaign",

					createButtonName: "Edit Campaign",

					currentPage: 'campaign',

					urlPath: `/campaign/edit/${id}`,

					id: id,

					tableColumns: [
						{
							title: "Comment ID",
							field: "commentId",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
						},
						{
							title: "Message",
							field: "message",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
							width: 300,
						},
						{
							title: "From",
							field: "name",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
							width: 300,
						},
						{
							title: "Comment At",
							field: "createTime",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
						},
						{
							title: "Replied At",
							field: "repliedAt",
							sorter: "string",
							editor: false,
						},
					],

					tableOptions: {

						placeholder: "No Comments For This Post.",

						rowFormatter: function (row)
						{
							row.getElement().classList.add("table-dark"); //mark rows with age greater than or equal to 18 as successful;
						},
					}
				});

				console.log("NOT FETCHED!!!");

				fetch(apiUrl + "/campaign/detail", {
					method: 'POST',
					headers: {
						authorization: user.token
					},
					body: JSON.stringify({id: id})
				}).then(response => response.json())    // one extra step
				.then(data =>
				{
					console.log("Campaign Detail Data");
					console.log(data);

					const campaignDetail = [
						{name: 'Post ID', value: data.campaign.postId},
						{name: 'Post Content', value: data.campaign.content},
						{name: 'Active From', value: moment(data.campaign.activeFrom).format('YYYY-MM-DD HH:mm:ss')},
						{name: 'Active To', value: moment(data.campaign.activeTo).format('YYYY-MM-DD HH:mm:ss')},
						{name: 'Status', value: (data.campaign.isActive) ? 'Active' : 'Inactive'},
					];

					setPageContextState({
						detail: campaignDetail,
					});

					console.log("This is Detail Data!!!!");
					console.log(detail);

					state.fetched = true;
					state.fetching = false;
					state.comments = data.comments;

					setState({
						...state
					});

					setMainContextState({
						loading: false
					});

				}).catch((err) =>
				{
					console.log('錯誤:', err);
				})
			}
		}

	}, [state.fetched]);

	return (
	<CampaignDetailContext.Provider
	value={{...state}}>
		{props.children}
	</CampaignDetailContext.Provider>
	);
}
