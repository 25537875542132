import React, {createContext, useState, useEffect} from 'react';
import $ from 'jquery';
import feather from 'feather-icons';
import io from "socket.io-client";

export const MainContext = createContext();

export default function MainContextProvider(props)
{
	const [state, setState] = useState({
		apiUrl: process.env.REACT_APP_API_URL,
		appUrl: process.env.REACT_APP_APP_URL,
		user: (window.localStorage.getItem('user')) ? JSON.parse(window.localStorage.getItem('user')) : null,//{"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE2OTg4MjQzNTY5MTQwNjciLCJuYW1lIjoiTWFyZ29ycCBHdXJ1IiwiYWN0aXZhdGlvbklkIjoiNWM0YTllNGUxYzU3NDMxMWEwODE5MDdhIiwiaWF0IjoxNTg0ODg3Njk5fQ.eghmdP4WCEHHaoC-Z30Ga-b5Xpvsv2mwodQH3ZV0Qls"},
		activated: false,
		socket: null,
		loading: true,
		init: false,
	});

	const setMainContextState = (data) =>
	{
		for (var prop in data)
			state[prop] = data[prop];

		setState({
			...state
		});

		console.log("This is Main Context States");
		console.log(state);
	};

	const initModal = () =>
	{
		// ie
		if (!!navigator.userAgent.match(/MSIE/i) || !!navigator.userAgent.match(/Trident.*rv:11\./))
			$('body').addClass('ie');

		// iOs, Android, Blackberry, Opera Mini, and Windows mobile devices
		var ua = window['navigator']['userAgent'] || window['navigator']['vendor'] || window['opera'];

		if ((/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua))
			$('body').addClass('touch');

		// fix z-index on ios safari
		if ((/iPhone|iPod|iPad/).test(ua))
		{
			$(document, '.modal, .aside').on('shown.bs.modal', function (e)
			{
				var backDrop = $('.modal-backdrop');
				$(e.target).after($(backDrop));
			});
		}
	};

	const initWindowResize = () =>
	{
		$(window).on('resize', function ()
		{
			var $w = $(window).width()
			, $lg = 1200
			, $md = 991
			, $sm = 768;

			if ($w < $md)
				$('#aside').removeClass('hide');
		});
	};

	const initMenu = () =>
	{
		$('#asideToggle').click(function ()
		{
			$('#aside').toggleClass('hide');
		});

		feather.replace();
	};

	const initToggles = () =>
	{
		// nav
		$(document).on('click', '[data-nav] a', function (e)
		{
			var $this = $(this), $active, $li, $li_li;

			$li = $this.parent();
			$li_li = $li.parents('li');

			$active = $li.closest("[data-nav]").find('.active');

			$li_li.addClass('active');
			($this.next().is('ul') && $li.toggleClass('active')) || $li.addClass('active');

			$active.not($li_li).not($li).removeClass('active');

			if ($this.attr('href') && $this.attr('href') != '#')
				$(document).trigger('Nav:changed');
		});

		// toggleClass
		$(document).on('click', '[data-toggle-class]', function (e)
		{
			var $self = $(this);
			var attr = $self.attr('data-toggle-class');
			var target = $self.attr('data-toggle-class-target') || $self.attr('data-target');
			var closest = $self.attr('data-target-closest');
			var classes = (attr && attr.split(',')) || '',
			targets = (target && target.split(',')) || Array($self),
			key = 0;

			$.each(classes, function (index, value)
			{
				var target = closest ? $self.closest(targets[(targets.length == 1 ? 0 : key)]) : $(targets[(targets.length == 1 ? 0 : key)]),
				current = target.attr('data-class'),
				_class = classes[index];
				(current != _class) && target.removeClass(target.attr('data-class'));
				target.toggleClass(classes[index]);
				target.attr('data-class', _class);
				key++;
			});

			$self.toggleClass('active');

			if ($self.attr('href') === "#")
				e.preventDefault()
		});
	};

	const initMouseWheel = () =>
	{
		$('body').on('DOMMouseScroll mousewheel', function (e)
		{
			var $header = $('.scroll-header');

			if (e.originalEvent.detail > 0 || e.originalEvent.wheelDelta < 0)
				$header.removeClass('scroll-up').addClass('scroll-down');
			else
				$header.removeClass('scroll-down').addClass('scroll-up');

			if ($(window).scrollTop() == 0)
				$header.removeClass('scroll-up scroll-down');
		});

		$('.swapimg .handler').on('mousemove', function (e)
		{
			var offset = $(this).parent().offset();
			var X = Math.round(e.pageX - offset.left);
			console.log(X);
			$(this).parent().width(X);
		});
	};

	const initUnload = () =>
	{
		window.addEventListener("beforeunload", (ev) =>
		{
			console.log("Page Unloading");
			//window.localStorage.removeItem('user');
		});
	}

	useEffect(() =>
	{

		if (!state.init)
		{

			document.title = "Facebook Page Auto Reply System";
			document.body.className = 'layout-row bg-dark';
			initWindowResize();
			initMouseWheel();
			initUnload();


			if (!state.user && !state.socket)
				state.socket = io(state.apiUrl);


			if (state.user && state.activated)
			{
				initMenu();
				initModal();
				initToggles();
			}

			setState({
				...state,
				isInit: true
			})
		}

		let data = {};

		for (var prop in data)
			state[prop] = data[prop];

		setState({
			...state
		})

	}, [state.user, state.activated]);


	return (
	<MainContext.Provider value={{...state, setMainContextState}}>
		{props.children}
	</MainContext.Provider>
	);
}
