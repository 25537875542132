import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import {MainContext} from "../contexts/MainContext";

export default function ActivationContainer()
{
	const {apiUrl, activated, user,setMainContextState} = useContext(MainContext);

	const [state, setState] = useState({
		isInit: false,
	});

	const startAuth = (e) =>
	{
		if (!state.disabled)
		{
			e.preventDefault();

			setState({
				...state,
				disabled: 'disabled'
			})
		}
	};

	useEffect(() =>
	{
		if(!state.isInit)
		{
			fetch(apiUrl + "/auth/user", {
				method:'POST',
				headers: {
					authorization: user.token
				}
			}).then(response => response.json())    // one extra step
			.then(data =>
			{
				console.log("This is User Auth");

				setMainContextState({
					activated:data.activated
				});

			}).catch((err) =>
			{
				console.log('錯誤:', err);
			});

			setState({
				...state,
				isInit: true,
			});
		}
	}, [state.isInit]);

	return (
	(function ()
	{
		if (!user)
		{
			return <Redirect to="/login"/>;
		}
		else
		{
			if (activated)
				return <Redirect to="/"/>;
			else
				return <div id="main" className="layout-column flex">
					<div className="container h-100">
						<div className="row h-100 justify-content-center align-items-center">
							<div className="w-50 w-auto-sm mx-auto py-5 text-center">
								{/*<span><img src="images/chatbot.png"/></span>*/}
								<h3 className="hidden-folded d-inline text-highlight">Facebook Page
									Auto
									Reply System</h3>
								<div className="px-3 py-2 text-center">
									{
										state.isInit ?
										<button
										className="btn btn-primary text-md my-4 py-3 px-4 font-weight-500"
										onClick={(e) =>
										{
											startAuth(e)
										}}>Activation With Facebook</button> : ""
									}
								</div>
							</div>
						</div>
					</div>
				</div>
		}
	})()
	);
}