import React, {useContext, useEffect, useState} from 'react';
import Dropzone from 'react-dropzone-uploader';
import mime from "mime-types";

import {FormContext} from "../contexts/FormContext";

export default function AttachmentTemplate(props)
{
	const {data, setFormContextState, getFileArray, fileUpload} = useContext(FormContext);
	const [state, setState] = useState({
		attachment: null,
		fetched: false,
		fetching: false
	});

	const setAttachmentType = (e) =>
	{
		const type = e.target.value;

		if (props.fallback)
		{
			data.fallbackPM.content = {
				...data.fallbackPM.content,
				type: type,
			}
		}
		else if (props.type === 'pm')
		{
			data.keywords[props.parent].replies[props.index].pm[props.type].content = {
				...data.keywords[props.parent].replies[props.index].pm[props.type].content,
				type: type,
			}
		}
		else
		{
			data.keywords[props.parent].pm[props.type].content = {
				...data.keywords[props.parent].pm[props.type].content,
				type: type,
			}
		}

		setFormContextState({
			data: data
		});

		return false;
	};

	const onFileChange = async ({meta, file}, status) =>
	{
		console.log("File Change");
		console.log(status);

		if (status !== 'removed' && status !== 'done')
			return false;

		let attachment = (status !== 'removed') ? file : null;

		console.log(attachment);

		if (attachment !== null)
		{
			attachment = await getFileArray(file);

			fileUpload(attachment, file.type, (url) =>
			{
				if (props.fallback)
				{
					data.fallbackPM.content = {
						...data.fallbackPM.content,
						file: url,
					}
				}
				else if (props.type === 'pm')
				{
					data.keywords[props.parent].replies[props.index].pm[props.type].content = {
						...data.keywords[props.parent].replies[props.index].pm[props.type].content,
						file: url,
					}
				}
				else
				{
					data.keywords[props.parent].pm[props.type].content = {
						...data.keywords[props.parent].pm[props.type].content,
						file: url,
					}
				}
			})
		}
		else
		{
			if (props.fallback)
			{
				data.fallbackPM.content = {
					...data.fallbackPM.content,
					file: attachment,
				}
			}
			else if (props.type === 'pm')
			{
				data.keywords[props.parent].replies[props.index].pm[props.type].content = {
					...data.keywords[props.parent].replies[props.index].pm[props.type].content,
					file: attachment
				}
			}
			else
			{
				data.keywords[props.parent].pm[props.type].content = {
					...data.keywords[props.parent].pm[props.type].content,
					file: attachment
				}
			}
		}

		return false;
	};

	useEffect(() =>
	{
		let attachment = null;

		if (props.fallback)
			attachment = data.fallbackPM.content.file;
		else if (props.type === 'pm')
			attachment = data.keywords[props.parent].replies[props.index].pm[props.type].content.file;
		else
			attachment = data.keywords[props.parent].pm[props.type].content.file;

		if (!state.fetched && state.fetching)
		{
			if (attachment !== null)
			{
				fetch(attachment, {
					method: 'get',
					responseType: 'arraybuffer'
				}).then(res =>
				{
					return res.arrayBuffer();
				}).then(arraybuffer =>
				{
					state.attachment = new File([arraybuffer], attachment.substring(attachment.lastIndexOf('/') + 1), {type: mime.lookup(attachment)});

					setState({
						...state,
						fetched: true,
						fetching: false
					});
				})
			}
		}
	}, [state.fetched]);

	return (
	<>
		<div className="form-group label">
			Attachment Type
		</div>


		<div className="form-group">
			{
				(function ()
				{
					let attachmentType;

					if (props.fallback)
						attachmentType = data.fallbackPM.content.type;
					else if (props.type === 'pm')
						attachmentType = data.keywords[props.parent].replies[props.index].pm[props.type].content;
					else
						attachmentType = data.keywords[props.parent].pm[props.type].content;


					return <select className="custom-select" name="attachmentType"
					               onChange={setAttachmentType}
					               value={attachmentType}>
						<option value="image">Image</option>
						<option value="audio">Audio</option>
						<option value="video">Video</option>
						<option value="file">File</option>
					</select>

				})()
			}
		</div>

		<div className="form-group label">
			Attachment File
		</div>

		<div className="form-group">
			{
				(state.attachment) ? <Dropzone
				onChangeStatus={onFileChange}
				multiple={false}
				maxFiles="1"
				initialFiles={[state.attachment]}
				/> : <Dropzone
				onChangeStatus={onFileChange}
				multiple={false}
				maxFiles="1"
				/>
			}
		</div>
	</>
	)
}