import React, {createContext, useState, useEffect, useContext} from 'react';
import {useParams} from "react-router-dom";

import {PageContext} from "./PageContext";
import {MainContext} from "./MainContext";
import {FacebookDataContext} from "./FacebookDataContext";


export const FacebookCampaignContext = createContext();

export default function FacebookCampaignContextProvider(props)
{
	const {apiUrl, user, setMainContextState} = useContext(MainContext);

	const {setPageContextState} = useContext(PageContext);

	const {setFacebookDataContextState} = useContext(FacebookDataContext);

	const {id} = useParams();

	const [state, setState] = useState({
		page: id,
		header: "Facebook Campaigns",
		fetched: false,
		fetching: false,
		campaigns: [],
	});

	const setFacebookCampaignContextState = (data) =>
	{
		for (var prop in data)
			state[prop] = data[prop];

		setState({
			...state
		});
	};

	const createFacebookPage = (data) =>
	{
		fetch(apiUrl + "/page", {
			method: 'PUT',
			headers: {
				'content-type': 'application/json',
				authourization: user.token
			},
			body: JSON.stringify(data),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			setFacebookDataContextState({
				modalOpen: false,
				before: null,
				after: null,
				facebookData: [],
			});

			setState({
				...state,
				pages: data
			});

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	};

	const removeCampaign = (data) =>
	{
		fetch(apiUrl + "/campaign", {
			method: 'DELETE',
			headers: {
				'content-type': 'application/json',
				authorization: user.token
			},
			body: JSON.stringify(data),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{

			setState({
				...state,
				campaigns: data
			});

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	}

	useEffect(() =>
	{
		console.log("========== THIS IS FACEBOOK CAMPAIGN CONTEXT ==========");

		if (user)
		{
			setPageContextState({
				tableData: state.campaigns,
				header: state.header,
			});

			if (!state.fetched && !state.fetching)
			{
				state.fetching = true;

				setPageContextState({
					header: "Facebook Campaigns",

					subtitle: "Campaigns To Auto Reply To Keywords",

					createButtonName: "Create Campaign",

					currentPage: 'campaign',

					urlPath: `/campaign/create/${id}`,

					id: id,

					page: id,

					tableColumns: [
						{
							title: "Post ID",
							field: "postId",
							sorter: "string",
							editor: false,
							validator: 'required',
							formatter: (cell, formatterParams, onRendered) =>
							{
								let postDetail=cell.getValue().split('_');

								return `<a href="https://www.facebook.com/${postDetail[0]}/posts/${postDetail[1]}" target="_blank">${cell.getValue()}</a>`
							},

						},
						{
							title: "Post Content",
							field: "content",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
							width: 300,
						},
						{
							title: "Active From",
							field: "activeFrom",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
							/*widthGrow: 1,*/
						},
						{
							title: "Active To",
							field: "activeTo",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
							/*widthGrow: 1,*/
						},
						{
							title: "Is Active ?",
							field: "isActive",
							sorter: "string",
							editor: false,
							formatter: (cell, formatterParams, onRendered) =>
							{
								let rowData = cell.getRow().getData();

								if (rowData.isActive)
									return `<span class="text-green">Yes</span>`;

								return `<span class="text-red">No</span>`;
							},
							validator: 'required'
						},
						{
							title: 'Action',
							/*width: 100,*/
							formatter: (cell, formatterParams, onRendered) =>
							{
								let rowData = cell.getRow().getData();

								if (typeof rowData.postId !== 'undefined')
									return `<a href="/campaign/edit/${rowData.id}">
									<button id='create' class='btn btn-primary'>Edit</button>
									</a>
									<a href="/campaign/${rowData.id}">
									<button id='view' class='btn btn-green'>View Detail</button>
									</a>
									<button id='delete' class='btn btn-red'>Delete</button>`;
							},
							align: "center",
							cellClick: function (e, cell)
							{
								let rowData = cell.getRow().getData();
								rowData.userId = user.id;

								switch (e.target.id)
								{
									case `edit`:
										break;
									case `view`:
										break;
									case `delete`:
										if (window.confirm("Confirm To Delete Record?"))
										{
											removeCampaign(rowData);
										}
										break;

								}
							}
						},
					],

					tableOptions: {

						placeholder: "No Campaigns",

						rowFormatter: function (row)
						{
							row.getElement().classList.add("table-dark"); //mark rows with age greater than or equal to 18 as successful;
						},
					}
				});

				fetch(apiUrl + `/${id}/campaigns`, {
					headers: {
						authorization: user.token
					}
				}).then(response => response.json())    // one extra step
				.then(data =>
				{
					state.fetching = false;
					state.fetched = true;

					console.log(data);

					setState({
						...state,
						campaigns: data.campaigns,
						header: data.page,
					});

					setMainContextState({
						loading: false
					});

				}).catch((err) =>
				{
					console.log('錯誤:', err);
				})
			}
		}

	}, [state.campaigns, state.fetched]);

	return (
	<FacebookCampaignContext.Provider
	value={{...state, setFacebookCampaignContextState}}>
		{props.children}
	</FacebookCampaignContext.Provider>
	);
}
