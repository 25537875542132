import React, {useContext} from 'react';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import {Redirect} from "react-router-dom";
import {Accordion} from "react-bootstrap";

import {FormContext} from "../contexts/FormContext";

import PageHeader from "../components/PageHeader";
import Modal from "../components/Modal";
import CommentReply from "../components/CommentReply";
import Keyword from "../components/Keyword";
import PrivateReply from "../components/PrivateReply";
import Error from "../components/Error";

export default function FormContainer(props)
{
    const {dateLoaded,submitted, data, setFormContextState, fetchFacebookPosts, submitForm}=useContext(FormContext);

    const yesterday=moment().subtract(1, 'day');

    const validateDate=(current) =>
    {
        return current.isAfter(yesterday);
    };

    const addKeyword=(e) =>
    {
        const keyword={
            keyword: '',
            replies: [],
            random: false,
            allowDuplicate: false,
            linkCMPM: false,
            comment: {
                replied: {text: '', image: null},
                noQuota: {text: '', image: null},
            },
            pm: {
                replied: {type: 'text', content: null, quickReplies: []},
                noQuota: {type: 'text', content: null, quickReplies: []},
            },
            cache: {
                replied: {type: 'text', content: null, quickReplies: []},
                noQuota: {type: 'text', content: null, quickReplies: []},
            }
        };

        data.keywords.push(JSON.parse(JSON.stringify(keyword)));

        setFormContextState({
            data: data
        });
    };

    const removePost=(e) =>
    {
        data.postId=null;
        data.postTitle=null;

        setFormContextState({
            data: data
        });
    };

    const onActiveFromChange=(e) =>
    {
        if (moment.isMoment(e))
            data.activeFrom=e.format('YYYY-MM-DDTHH:mm:ss');
    };

    const onActiveToChange=(e) =>
    {
        if (moment.isMoment(e))
            data.activeTo=e.format('YYYY-MM-DDTHH:mm:ss');
    };

    const toggleSwitch=(e) =>
    {
        const type=e.target.dataset.attr;

        data[type]= !data[type];

        setFormContextState({
            data: data
        });
    };

    return (
    (submitted) ? <Redirect to={`/${data.pageId}/campaigns`}/> :
    <div id="content" className="flex">

        <Error error={data.error}/>

        <PageHeader/>
        <div className="page-container formContainer px-5">
            <form id="createCampaignForm" action="/campaign/create" method="POST"
                  encType="multipart/form-data" className="py-3">

                <div className="form-group">
                    <label htmlFor="">Post</label>
                </div>

                {
                    (data.postId===null)?<div className="form-group createPost">

                        <button id="addPost" type="button" className="btn btn-orange"
                                data-toggle="modal"
                                data-target=".modal" onClick={fetchFacebookPosts}>Add
                            Post
                        </button>
                    </div>:<div className="form-group postTitle">
                        <h4 className="d-inline-block title font-weight-bold">
                            {data.postTitle}
                        </h4>
                        <button type="button" className="close removePostBtn"
                                onClick={removePost}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                }

                <div className="form-group">
                    <label htmlFor="">Keyword(s)</label>
                </div>

                <Accordion defaultActiveKey="0" id="keywordAccordion">
                    {
                        data.keywords.map((item, i) => <Keyword key={i} index={i}/>)
                    }
                </Accordion>

                <div className="form-group">
                    <button type="button" className="btn btn-orange" onClick={addKeyword}>Add
                        Keyword
                    </button>
                </div>

                {
                    (dateLoaded) && <>
                        <div className="form-group datetime">
                            <label className="mb-3">Active From</label>
                            <Datetime
                            dateFormat="YYYY-MM-DD"
                            timeFormat="HH:mm"
                            defaultValue={moment(data.activeFrom)}
                            data-type="activeFrom"
                            isValidDate={validateDate}
                            onChange={onActiveFromChange}
                            inputProps={{
                                readOnly: true
                            }}
                            />
                        </div>

                        <div className="form-group datetime">
                            <label className="mb-3">Active To</label>
                            <Datetime
                            dateFormat="YYYY-MM-DD"
                            timeFormat="HH:mm"
                            defaultValue={moment(data.activeTo)}
                            onChange={onActiveToChange}
                            isValidDate={validateDate}
                            />
                        </div>
                    </>
                }

                <div className="form-group">
                    <label>Allow User To Comment With Different Keywords?</label>
                </div>

                <div className="form-group">
                    <div className="custom-control custom-switch mb-3">
                        <input type="checkbox" className="custom-control-input"
                               id="duplicateKeyword-switch"
                               data-attr="duplicateKeyword"
                               onChange={toggleSwitch} checked={data.duplicateKeyword}/>
                        <label className="custom-control-label"
                               htmlFor="duplicateKeyword-switch"></label>
                    </div>
                </div>

                {
                    (!data.duplicateKeyword) && <>
                        <div className="form-group">
                            <label htmlFor="">Fallback Comment Reply</label>
                        </div>

                        < CommentReply class="fallbackComment"
                                       id="fallbackComment"
                                       index={props.index} fallback={true}/>

                        <div className="form-group">
                            <label htmlFor="">Fallback Private Reply</label>
                        </div>

                        <PrivateReply class="fallBackPM"
                                      id="fallbackPM"
                                      index={props.index} fallback={true}/>
                    </>
                }

                <div className="form-group">
                    <label>Active</label>
                </div>

                <div className="form-group">
                    <div className="custom-control custom-switch mb-3">
                        <input type="checkbox" className="custom-control-input" id="isActive-switch"
                               data-attr="isActive"
                               onChange={toggleSwitch} checked={data.isActive}/>
                        <label className="custom-control-label" htmlFor="isActive-switch"></label>
                    </div>
                </div>


                <div className="form-group py-3 text-right">
                    <a href="/">
                        <button type="button" className="btn btn-secondary">Cancel</button>
                    </a>

                    <button type="button" className="btn btn-primary" onClick={submitForm}>Submit
                    </button>
                </div>
            </form>
        </div>
        <Modal title="Add Post" onClick={fetchFacebookPosts}/>
    </div>
    );
}