import React, {useContext} from 'react';

import {MainContext} from "../contexts/MainContext";

export default function Loading()
{
    const {loading}=useContext(MainContext);
    return (
    (loading) && <div id="loading" className="layout-column flex">
	    <div className="col-sm-12 h-100 d-table">
		    <div className="card card-block d-table-cell align-middle">
			    <img src="https://assets.facebookautoreply.com/load.svg"/>
		    </div>
	    </div>
    </div>
    );
}