import React, {useContext, useEffect, useState} from 'react';

import {FormContext} from "../contexts/FormContext";
import Dropzone from 'react-dropzone-uploader';
import isUrl from 'is-url';
import mime from "mime-types";

export default function QuickReply(props)
{
	const {data, setFormContextState, getFileArray, fileUpload} = useContext(FormContext);
	const [state, setState] = useState({
		image: null,
		fetched: false,
		fetching: false
	});

	const setQuickReplyType = (e) =>
	{
		const type = e.target.value;

		if (props.fallback)
			data.fallbackPM.quickReplies[props.index].type = type;
		else if (props.type === 'pm')
			data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies[props.index].type = type;
		else
			data.keywords[props.parent].pm[props.type].quickReplies[props.index].type = type;

		setFormContextState({
			data: data
		});

		return false;
	};

	const setQuickReplyTitle = (e) =>
	{
		if (props.fallback)
			data.fallbackPM.quickReplies[props.index].title = e.target.value;
		else if (props.type === 'pm')
			data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies[props.index].title = e.target.value;
		else
			data.keywords[props.parent].pm[props.type].quickReplies[props.index].title = e.target.value;

		setFormContextState({
			data: data
		});

		return false;
	};

	const setQuickReplyPayload = (e) =>
	{
		const payload = e.target.value;

		if (props.fallback)
			data.fallbackPM.quickReplies[props.index].payload = payload;
		else if (props.type === 'pm')
			data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies[props.index].payload = payload;
		else
			data.keywords[props.parent].pm[props.type].quickReplies[props.index].payload = payload;

		setFormContextState({
			data: data
		});

		return false;
	};

	const onFileChange = async ({meta, file}, status) =>
	{
		if (status !== 'removed' && status !== 'done')
			return false;

		let image = (status !== 'removed') ? file : null;

		if (image !== null)
		{
			image = await getFileArray(file);

			fileUpload(image, file.type, (url) =>
			{
				if (props.fallback)
					data.fallbackPM.quickReplies[props.index].image = url;
				else if (props.type === 'pm')
					data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies[props.index].image = url;
				else
					data.keywords[props.parent].pm[props.type].quickReplies[props.index].image = url;
			})
		}
		else
		{
			if (props.fallback)
				data.fallbackPM.quickReplies[props.index].image = image;
			else if (props.type === 'pm')
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies[props.index].image = image;
			else
				data.keywords[props.parent].pm[props.type].quickReplies[props.index].image = image;
		}

		return false;
	};

	const removeQuickReply = (e) =>
	{
		if (props.fallback)
			data.fallbackPM.quickReplies.splice(props.index, 1);
		else if (props.type === 'pm')
			data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies.splice(props.index, 1);
		else
			data.keywords[props.parent].pm[props.type].quickReplies.splice(props.index, 1);

		setFormContextState({
			data: data
		});

		return false;
	};

	useEffect(() =>
	{
		let image;

		if (props.fallback)
			image = data.fallbackPM.quickReplies[props.index].image;
		else if (props.type === 'pm')
			image = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies[props.index].image;
		else
			image = data.keywords[props.parent].pm[props.type].quickReplies[props.index].image;

		if (!state.fetched && !state.fetching)
		{
			state.fetching = true;

			if (image !== null && isUrl(image))
			{
				fetch(image, {
					method: 'get',
					responseType: 'arraybuffer'
				}).then(res =>
				{
					return res.arrayBuffer();
				}).then(arraybuffer =>
				{
					state.image = new File([arraybuffer], image.substring(image.lastIndexOf('/') + 1), {type: mime.lookup(image)});

					setState({
						...state,
						fetched: true,
						fetching: false
					});
				})
			}
		}
	}, [state.fetched]);

	return (
	<>
		<hr/>

		<h5 className="form-group">Quick Reply #{props.index + 1}</h5>

		<div className="form-group label">Type</div>

		<div className="form-group">
			{
				(function ()
				{
					let type;

					if (props.fallback)
						type = data.fallbackPM.quickReplies[props.index].type;
					else if (props.type === 'pm')
						type = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies[props.index].type;
					else
						type = data.keywords[props.parent].pm[props.type].quickReplies[props.index].type;

					return <select className="custom-select" value={type}
					               onChange={setQuickReplyType}>
						<option value="text">Text</option>
						<option value="phone">Phone</option>
						<option value="email">Email</option>
					</select>
				})()
			}
		</div>

		<div className="form-group label">Title</div>
		<div className="form-group">
			{
				(function ()
				{
					let title;

					if (props.fallback)
						title = data.fallbackPM.quickReplies[props.index].title;
					else if (props.type === 'pm')
						title = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies[props.index].title;
					else
						title = data.keywords[props.parent].pm[props.type].quickReplies[props.index].title;

					return <input type="text" className="form-control quickReplyTitle"
					              aria-describedby="keyword" onChange={setQuickReplyTitle}
					              placeholder="Enter Button Title" value={title}/>
				})()
			}

		</div>

		<div className="form-group label">Payload</div>
		<div className="form-group">
			{
				(function ()
				{
					let payload;

					if (props.fallback)
						payload = data.fallbackPM.quickReplies[props.index].payload;
					else if (props.type === 'pm')
						payload = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].quickReplies[props.index].payload;
					else
						payload = data.keywords[props.parent].pm[props.type].quickReplies[props.index].payload;

					return <input type="text" className="form-control payload"
					              onChange={setQuickReplyPayload}
					              value={payload}
					              placeholder="Payload" required=""/>
				})()
			}

		</div>

		<div className="form-group label">Image</div>

		<div className="form-group">
			{
				(state.image) ? <Dropzone
				onChangeStatus={onFileChange}
				multiple={false}
				maxFiles="1"
				initialFiles={[state.image]}
				/> : <Dropzone
				onChangeStatus={onFileChange}
				multiple={false}
				maxFiles="1"
				/>
			}
		</div>

		<div className="form-group">
			<button type="button" className="btn btn-red" onClick={removeQuickReply}>Remove</button>
		</div>

	</>);
}