import React, {useContext} from 'react';

import CommentReply from "./CommentReply";
import PrivateReply from "./PrivateReply";
import {Accordion, Button, Card} from "react-bootstrap";
import {FormContext} from "../contexts/FormContext";

export default function Reply(props)
{

    const {data, setFormContextState}=useContext(FormContext);

    const toggleComment=(e) =>
    {
        const haveComment=data.keywords[props.parent].replies[props.index].haveComment;
        data.keywords[props.parent].replies[props.index].haveComment= !haveComment;

        setFormContextState({
            data: data,
        });

        return false;
    };

	const togglePM=(e) =>
	{
        const havePM = data.keywords[props.parent].replies[props.index].havePM;
        data.keywords[props.parent].replies[props.index].havePM = !havePM;

        setFormContextState({
            data: data,
        });

        return false;
    };

	const changeQuota=(e)=>
    {
        const type=e.target.dataset.type;
        data.keywords[props.parent].replies[props.index][type].quota= parseInt(e.target.value);

        setFormContextState({
            data: data,
        });

        return false;
    };

    const removeReply=(e) =>
    {
        const reply=e.target.dataset;
        data.keywords[reply.parent].replies.splice(reply.index, 1);

        setFormContextState({
            data: data,
        });

        return false;
    };

    return (

    <Card>
        <Accordion.Toggle as={Button} variant="link" eventKey={props.index}>
            <Card.Header>
                <h6 className="mb-0 d-inline-block weight-700">
                    Reply #{props.index + 1}
                </h6>
            </Card.Header>
            <div className="close d-inline-block pb-2 pt-1 pl-2 pr-2" onClick={removeReply}>
                <span aria-hidden="true" data-index={props.index} data-parent={props.parent}>&times;</span>
            </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={props.index} data-index={props.index} data-parent={props.parent}>
            <Card.Body>

                <h5 className="form-group pl-0">Have Comment?</h5>

                <div className="custom-control custom-switch mb-3">
                    <input type="checkbox" className="custom-control-input"
                           id={`keyword${props.parent}-reply${props.index}-haveComment`} onChange={toggleComment}
                           checked={data.keywords[props.parent].replies[props.index].haveComment}
                    />
                    <label className="custom-control-label"
                           htmlFor={`keyword${props.parent}-reply${props.index}-haveComment`}></label>
                </div>

                <hr/>
                {
                    (data.keywords[props.parent].replies[props.index].haveComment) &&
                    <>
                        <h4 className="subtitle">Comment Replies</h4>

                        <h5 className="form-group">Quota</h5>

                        <div className="form-group">
                            <input type="number"
                                   className="form-control quota"
                                   name={`keywords[${props.parent}]["cmQuota"]`}
                                   aria-describedby="quota"
                                   placeholder="Input 0 for No Limit"
                                   data-type="comment"
                                   value={data.keywords[props.parent].replies[props.index][`comment`].quota}
                                   onChange={changeQuota}
                            />
                            <small className="form-text text-muted"></small>
                        </div>

                        <CommentReply title="Comment Reply" class="comment" parent={props.parent} index={props.index} type="comment" fallback={false}/>
                        <hr/>
                    </>
                }

                <h5 className="form-group pl-0">Have Private Reply?</h5>

                <div className="custom-control custom-switch mb-3">
                    <input type="checkbox" className="custom-control-input"
                           id={`keyword${props.parent}-reply${props.index}-havePM`}
                           onChange={togglePM}
                           checked={data.keywords[props.parent].replies[props.index].havePM}/>
                    <label className="custom-control-label"
                           htmlFor={`keyword${props.parent}-reply${props.index}-havePM`}></label>
                </div>

                {
                    (data.keywords[props.parent].replies[props.index].havePM) &&
                    <>
                        <hr/>
                        <h4 className="subtitle">Private Replies</h4>

                        <h5 className="form-group">Quota</h5>

                        <div className="form-group">
                            <input type="number"
                                   className="form-control quota"
                                   name={`keywords[${props.parent}]["pmQuota"]`}
                                   aria-describedby="quota"
                                   placeholder="Input 0 for No Limit"
                                   data-type="pm"
                                   value={data.keywords[props.parent].replies[props.index][`pm`].quota}
                                   onChange={changeQuota}
                            />
                        </div>

                        <PrivateReply title="Private Reply" parent={props.parent}
                                      index={props.index} type="pm" fallback={false}/>
                    </>
                }
            </Card.Body>
        </Accordion.Collapse>
    </Card>)
}