import React, {useContext} from 'react';
import {FormContext} from "../contexts/FormContext";

export default function UrlButton(props)
{
	const {data, setFormContextState} = useContext(FormContext);

	const onSelectChange = (e) =>
	{
		const ratio = e.target.value;

		if (props.fallback)
		{
			if (typeof props.defaultAction !== 'undefined')
				data.fallbackPM.content.elements[props.bubbleParent].defaultAction.ratio = ratio;
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].content.ratio = ratio;
				else
					data.fallbackPM.content.buttons[props.index].content.ratio = ratio;
			}
		}
		else if (props.type === 'pm')
		{
			if (typeof props.defaultAction !== 'undefined')
			{
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.ratio = ratio;
			}
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.ratio = ratio;
				else
					data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].content.ratio = ratio;
			}
		}
		else
		{
			if (typeof props.defaultAction !== 'undefined')
			{
				data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.ratio = ratio;
			}
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.ratio = ratio;
				else
					data.keywords[props.parent].pm[props.type].content.buttons[props.index].content.ratio = ratio;
			}
		}


		setFormContextState({
			data: data
		});

		return false;
	};

	const setUrl = (e) =>
	{
		const url = e.target.value;

		if (props.fallback)
		{
			if (typeof props.defaultAction !== 'undefined')
				data.fallbackPM.content.elements[props.bubbleParent].defaultAction.url = url;
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].content.url = url;
				else
					data.fallbackPM.content.buttons[props.index].content.url = url;
			}
		}
		else if (props.type === 'pm')
		{
			if (typeof props.defaultAction !== 'undefined')
			{
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.url = url;
			}
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.url = url;
				else
					data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].content.url = url;
			}
		}
		else
		{
			if (typeof props.defaultAction !== 'undefined')
			{
				data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.url = url;
			}
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.url = url;
				else
					data.keywords[props.parent].pm[props.type].content.buttons[props.index].content.url = url;
			}
		}


		setFormContextState({
			data: data
		});

		return false;
	};

	const toggleMessengerExtension = (e) =>
	{
		const isMessengerExtension = e.target.checked;

		if (props.fallback)
		{
			if (typeof props.defaultAction !== 'undefined')
				data.fallbackPM.content.elements[props.bubbleParent].defaultAction.isMessengerExtension = isMessengerExtension;
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].content.isMessengerExtension = isMessengerExtension;
				else
					data.fallbackPM.content.buttons[props.index].content.isMessengerExtension = isMessengerExtension;
			}
		}
		else if (props.type === 'pm')
		{
			if (typeof props.defaultAction !== 'undefined')
			{
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.isMessengerExtension = isMessengerExtension;
			}
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.isMessengerExtension = isMessengerExtension;
				else
					data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].content.isMessengerExtension = isMessengerExtension;
			}
		}
		else
		{
			if (typeof props.defaultAction !== 'undefined')
			{
				data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.isMessengerExtension = isMessengerExtension;
			}
			else
			{
				if (typeof props.isElement !== 'undefined')
					data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.isMessengerExtension = isMessengerExtension;
				else
					data.keywords[props.parent].pm[props.type].content.buttons[props.index].content.isMessengerExtension = isMessengerExtension;
			}
		}

		setFormContextState({
			data: data
		});

		return false;
	};

	return (
	<>
		<div className="form-group label">URL</div>

		<div className="form-group">
			{
				(function ()
				{
					let url;

					if (props.fallback)
					{
						if (typeof props.defaultAction !== 'undefined')
							url = data.fallbackPM.content.elements[props.bubbleParent].defaultAction.url;
						else
						{
							if (typeof props.isElement !== 'undefined')
								url = data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].content.url;
							else
								url = data.fallbackPM.content.buttons[props.index].content.url;
						}
					}
					else if (props.type === 'pm')
					{
						if (typeof props.defaultAction !== 'undefined')
						{
							url = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.url;
						}
						else
						{
							if (typeof props.isElement !== 'undefined')
								url = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.url;
							else
								url = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].content.url;
						}
					}
					else
					{
						if (typeof props.defaultAction !== 'undefined')
						{
							url = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.url;
						}
						else
						{
							if (typeof props.isElement !== 'undefined')
								url = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.url;
							else
								url = data.keywords[props.parent].pm[props.type].content.buttons[props.index].content.url;
						}
					}

					return <input type="text" className="form-control buttonUrl"
					              aria-describedby="keyword" onChange={setUrl}
					              placeholder="Enter URL" value={url}/>
				})()
			}
		</div>

		<div className="form-group label">Webview Height Ratio</div>

		<div className="form-group">
			{
				(function ()
				{
					let ratio;

					if (props.fallback)
					{
						if (typeof props.defaultAction !== 'undefined')
							ratio = data.fallbackPM.content.elements[props.bubbleParent].defaultAction.ratio;
						else
						{
							if (typeof props.isElement !== 'undefined')
								ratio = data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].content.ratio;
							else
								ratio = data.fallbackPM.content.buttons[props.index].content.ratio;
						}
					}
					else if (props.type === 'pm')
					{
						if (typeof props.defaultAction !== 'undefined')
						{
							ratio = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.ratio;
						}
						else
						{
							if (typeof props.isElement !== 'undefined')
								ratio = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.ratio;
							else
								ratio = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].content.ratio;
						}
					}
					else
					{
						if (typeof props.defaultAction !== 'undefined')
						{
							ratio = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.ratio;
						}
						else
						{
							if (typeof props.isElement !== 'undefined')
								ratio = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.ratio;
							else
								ratio = data.keywords[props.parent].pm[props.type].content.buttons[props.index].content.ratio;
						}
					}

					return <select className="custom-select" name="webviewRatio" value={ratio}
					               onChange={onSelectChange}>
						<option value="full">Full</option>
						<option value="tall">Tall</option>
						<option value="compact">Compact</option>
					</select>
				})()
			}

		</div>

		<div className="form-group label">Messenger Extension?</div>

		<div className="form-group">
			<div className="custom-control custom-switch">
				{
					(function ()
					{
						let isMessengerExtension;
						let id;

						if (props.fallback)
						{
							if (typeof props.defaultAction !== 'undefined')
							{
								id = `fallbackPM-defaultAction-isMessengerExtension`;
								isMessengerExtension = data.fallbackPM.content.elements[props.bubbleParent].defaultAction.isMessengerExtension;
							}
							else
							{
								if (typeof props.isElement !== 'undefined')
								{
									id = `fallbackPM--generic${props.bubbleParent}-button${props.index}-isMessengerExtension`;
									isMessengerExtension = data.fallbackPM.content.elements[props.bubbleParent].buttons[props.index].content.isMessengerExtension;
								}
								else
								{
									id = `fallbackPM-buttons${props.index}-isMessengerExtension`;
									isMessengerExtension = data.fallbackPM.content.buttons[props.index].content.isMessengerExtension;
								}
							}
						}
						else if (props.type === 'pm')
						{
							if (typeof props.defaultAction !== 'undefined')
							{
								id = `keyword${props.keywordParent}-replies${props.parent}-${props.type}-bubble${props.bubbleParent}-defaultAction-isMessengerExtension`;
								isMessengerExtension = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.isMessengerExtension;
							}
							else
							{
								if (typeof props.isElement !== 'undefined')
								{
									id = `keyword${props.keywordParent}-replies${props.parent}-${props.type}-bubble${props.bubbleParent}-button${props.index}-isMessengerExtension`;
									isMessengerExtension = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.isMessengerExtension;
								}
								else
								{
									id = `keyword${props.keywordParent}-replies${props.parent}-${props.type}-buttons${props.index}-isMessengerExtension`;
									isMessengerExtension = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.buttons[props.index].content.isMessengerExtension;
								}
							}
						}
						else
						{
							if (typeof props.defaultAction !== 'undefined')
							{
								id = `keyword${props.parent}-${props.type}-bubble${props.bubbleParent}-defaultAction-isMessengerExtension`;
								isMessengerExtension = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].defaultAction.isMessengerExtension;
							}
							else
							{
								if (typeof props.isElement !== 'undefined')
								{
									id = `keyword${props.parent}-${props.type}-bubble${props.bubbleParent}-buttons${props.index}-isMessengerExtension`;
									isMessengerExtension = data.keywords[props.parent].pm[props.type].content.elements[props.bubbleParent].buttons[props.index].content.isMessengerExtension;
								}
								else
								{
									id = `keyword${props.parent}-${props.type}-buttons${props.index}-isMessengerExtension`;
									isMessengerExtension = data.keywords[props.parent].pm[props.type].content.buttons[props.index].content.isMessengerExtension;
								}
							}
						}

						return <>
							<input type="checkbox" className="custom-control-input"
							       id={id} onChange={toggleMessengerExtension}
							       checked={isMessengerExtension}/>
							<label className="custom-control-label"
							       htmlFor={id}></label>
						</>
					})()
				}
			</div>
		</div>
	</>
	);
}