import React from 'react';
import {Editor} from '@tinymce/tinymce-react';

export default function TinyMCEEditor(props)
{
	const apiKey = "o74akgv6e50rohvgn14s7dogctkixi08bdtizmcp0prlxofm";

	return (<Editor apiKey={apiKey} initialValue=""
	                id={props.id}
	                textareaName={props.id}
	                init={{
		                height: 200,
		                menubar: false,
		                plugins: [
			                'emoticons',
			                'paste',
		                ],
		                toolbar: 'emoticons',
		                force_br_newlines: false,
		                force_p_newlines: false,
		                forced_root_block: '',
		                paste_as_text: true,
	                }}
	                value={props.content}
	                onEditorChange={props.onChange}
	/>)
}