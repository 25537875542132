import React, {useContext, useState, useEffect} from 'react';
import TinyMCEEditor from "../components/TinyMCEEditor";
import {FormContext} from "../contexts/FormContext";
import Dropzone from 'react-dropzone-uploader'
import isUrl from 'is-url';
import mime from 'mime-types';

export default function CommentReply(props)
{
	const {data, getFileArray, fileUpload} = useContext(FormContext);
	const [state, setState] = useState({
		image: null,
		fetched: false,
		fetching: false
	});


	const onEditorChange = (content) =>
	{
		if (props.fallback)
			data.fallbackComment.text = content;
		else if (props.type === 'comment')
			data.keywords[props.parent].replies[props.index].comment[props.type].text = content;
		else
			data.keywords[props.index].comment[props.type].text = content;


		return false;
	};

	const onFileChange = async ({meta, file}, status) =>
	{
		console.log(status);

		if (status !== 'removed' && status !== 'done')
			return false;

		let image = (status !== 'removed') ? file : null;

		if (image !== null && !isUrl(image))
		{
			image = await getFileArray(file);

			fileUpload(image, file.type, (url) =>
			{
				console.log(url);
				console.log(file.type);

				if (typeof (props.parent) === 'undefined')
					data.fallbackComment.image = url;
				else if (props.type === 'comment')
					data.keywords[props.parent].replies[props.index].comment[props.type].image = url;
				else
					data.keywords[props.index].comment[props.type].image = url;
			})
		}
		else
		{
			if (props.fallback)
				data.fallbackComment.image = image;
			else if (props.type === 'comment')
				data.keywords[props.parent].replies[props.index].comment[props.type].image = image;
			else
				data.keywords[props.index].comment[props.type].image = image;
		}

		return false;
	};

	const getEditorContent = () =>
	{
		if (props.fallback)
			return data.fallbackComment.text;
		else if (props.type === 'comment')
			return data.keywords[props.parent].replies[props.index].comment[props.type].text;
		else
		{
			return data.keywords[props.index].comment[props.type].text;
		}
	};

	useEffect(() =>
	{
		let image;

		if (props.fallback)
			image = data.fallbackComment.image;
		else if (props.type === 'comment')
			image = data.keywords[props.parent].replies[props.index].comment[props.type].image;
		else
			image = data.keywords[props.index].comment[props.type].image;

		if (!state.fetched && !state.fetching)
		{
			state.fetching = true;

			if (image !== null)
			{
				fetch(image, {
					method: 'get',
					responseType: 'arraybuffer'
				}).then(res =>
				{
					return res.arrayBuffer();
				}).then(arraybuffer =>
				{
					state.image = new File([arraybuffer], image.substring(image.lastIndexOf('/') + 1), {type: mime.lookup(image)});

					setState({
						...state,
						fetched: true,
						fetching: false
					});
				})
			}
		}
	}, [state.fetched]);

	return (
	<>
		{(props.title) && <h5 className="form-group">{props.title}</h5>}

		<div className="form-group">
			<TinyMCEEditor id={props.id} onChange={onEditorChange} content={getEditorContent()}/>
			<small className="form-text text-muted"></small>
		</div>

		{
			(props.fallback) ?
			<div className="form-group">
				<label>Reply Image</label>
			</div>
			: <h5 className="form-group">Reply Image</h5>
		}

		<div className="form-group">
			{
				(state.image) ? <Dropzone
				onChangeStatus={onFileChange}
				multiple={false}
				maxFiles="1"
				initialFiles={[state.image]}
				/> : <Dropzone
				onChangeStatus={onFileChange}
				multiple={false}
				maxFiles="1"
				/>
			}
		</div>
	</>);
}