import React, {createContext, useEffect, useState, useContext} from 'react';
import $ from "jquery";
import isUrl from 'is-url';
import moment from 'moment-timezone';

import {FacebookDataContext} from "./FacebookDataContext";
import {PageContext} from "./PageContext";
import {MainContext} from "./MainContext";


export const FormContext = createContext();

export default function FormContextProvider(props)
{
	const {apiUrl, user} = useContext(MainContext);
	const {id, currentPageType} = useContext(PageContext);
	const {setFacebookDataContextState, fetchFacebookData} = useContext(FacebookDataContext);

	const [state, setState] = useState({
		init: false,
		submitted: false,
		submitting: false,
		dateLoaded: false,
		data: {
			campaignId: null,
			pageId: id,
			postId: null,
			postTitle: null,
			keywords: [{
				keyword: '',
				replies: [],
				random: false,
				allowDuplicate: false,
				linkCMPM: false,
				comment: {
					replied: {text: '', image: null},
					noQuota: {text: '', image: null},
				},
				pm: {
					replied: {type: 'text', content: null, quickReplies: []},
					noQuota: {type: 'text', content: null, quickReplies: []},
				},
				cache: {
					replied: {type: 'text', content: null, quickReplies: []},
					noQuota: {type: 'text', content: null, quickReplies: []},
				}
			}],
			fallbackComment: {
				text: '',
				image: null
			},
			fallbackPM: {
				type: 'text',
				content: null,
				quickReplies: [],
			},
			cache: {type: null, content: null},
			activeFrom: null,
			activeTo: null,
			isActive: true,
			duplicateKeyword: true,
			error: null,
		}
	});

	const setFormContextState = (data) =>
	{
		for (var prop in data)
			state[prop] = data[prop];

		setState({
			...state
		});

		console.log(state.data);
	};

	const fetchFacebookPosts = (e) =>
	{
		let type = 'published_posts';

		if (e.target.id === 'scheduled')
			type = 'scheduled_posts';

		setFacebookDataContextState({
			type: type
		});

		fetchFacebookData(e);
	};

	const getArrayBuffer = (file) =>
	{
		return new Promise((resolve, reject) =>
		{
			// STEP 3: 轉成 ArrayBuffer, i.e., reader.result
			const reader = new FileReader();
			reader.addEventListener('load', () =>
			{
				return resolve(reader.result);
			});
			reader.readAsArrayBuffer(file);
		})
	};

	const getFileArray = async (file) =>
	{

		let buffer = await getArrayBuffer(file);
		return Array.from(new Uint8Array(buffer));
	};

	const fileUpload = (fileData, fileType, callback) =>
	{
		fetch(apiUrl + "/file/upload", {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				authorization: user.token,
			},
			body: JSON.stringify({
				fileData: fileData,
				fileType: fileType
			})
		})
		.then(response => response.json())
		.then(data =>
		{
			return callback(data.url);
		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	};

	const validateForm = () =>
	{
		state.data.error = null;

		if (state.data.postId === null || state.data.postTitle === null)
		{
			state.data.error = {message: "Must Select A Post For The Campaign."};
			return false;
		}

		if (state.data.keywords.length === 0)
		{
			state.data.error = {message: "Must Have At Least 1 Keyword For The Campaign."};
			return false;
		}

		for (let i = 0; i < state.data.keywords.length; i++)
		{
			const keyword = state.data.keywords[i];

			if (keyword.keyword.length === 0)
			{
				state.data.error = {message: "Please Input Keyword For Each Keywords"};
				return false;
			}

			if (keyword.replies.length === 0)
			{
				state.data.error = {message: "Must Have At Least 1 Reply For Each Keywords."};
				return false;
			}

			let haveUnlimitedComment = false;
			let haveUnlimitedPM = false;
			let noComment = 0;
			let noPM = 0;

			for (let a = 0; a < keyword.replies.length; a++)
			{
				const reply = keyword.replies[a];

				if (reply.comment.quota < 0 || reply.pm.quota < 0)
				{
					state.data.error = {message: "Comment Quota Must be Greater Than or Equal to 0"};
					return false;
				}

				if (!reply.haveComment && !reply.havePM)
				{
					state.data.error = {message: "Must Have Comment or PM For Each Replies."};
					return false;
				}

				if (reply.haveComment)
				{
					if (reply.comment.comment.text.length === 0 && reply.comment.comment.image === null)
					{
						state.data.error = {message: "Please Enter Text or Image For Comment."};
						return false;
					}
				}
				else
					noComment++;

				if (reply.havePM)
				{
					const pm = reply.pm.pm;

					if (!validatePrivateReply(pm, "Private Reply"))
						return false;
				}
				else
					noPM++;

				if (reply.comment.quota === 0 && reply.haveComment)
					haveUnlimitedComment = true;

				if (reply.pm.quota === 0 && reply.havePM)
					haveUnlimitedPM = true;

			}

			if (noComment !== keyword.replies.length && !haveUnlimitedComment)
			{
				if (keyword.comment.noQuota.text.length === 0)
				{
					state.data.error = {message: "Please Enter No Quota Comment Reply If All Comments Have Quota."};
					return false;
				}
			}

			if (noPM !== keyword.replies.length && !haveUnlimitedPM)
			{
				if (!validatePrivateReply(keyword.pm.noQuota, "No Quota Reply"))
					return false;
			}

			if (noComment !== keyword.replies.length && !keyword.allowDuplicate)
			{
				if (keyword.comment.replied.text.length === 0)
				{
					state.data.error = {message: "Please Enter Already Commented Reply If NOT Allow User Duplicate Reply"};
					return false;
				}
			}

			if (noPM !== keyword.replies.length && !keyword.allowDuplicate)
			{
				if (!validatePrivateReply(keyword.pm.replied, "Already Commented Reply"))
					return false;
			}
		}

		if (state.data.activeFrom === null)
		{
			state.data.error = {message: "Please Enter Campaign Start DateTime"};
			return false;
		}

		if (state.data.activeTo === null)
		{
			state.data.error = {message: "Please Enter Campaign End DateTime"};
			return false;
		}

		if (moment(state.data.activeFrom).isAfter(moment(state.data.activeTo)))
		{
			state.data.error = {message: "Campaign Must Start Before It Ends."};
			return false;
		}

		if (!state.data.duplicateKeyword)
		{
			if (state.data.fallbackComment.text.length === 0 && state.data.fallbackComment.image === null)
			{
				state.data.error = {message: "Please Enter Fallback Comment Reply If User is Not Allowed To Comment with Different Keywords."};
				return false;
			}

			if (!validatePrivateReply(state.data.fallbackPM, "Fallback Private Reply"))
				return false;
		}

		return true;

	};

	const validatePayload = (payload, replyType) =>
	{
		if (payload.length === 0)
		{
			state.data.error = {message: `Please Enter Payload For Button In ${replyType}.`};
			return false;
		}

		return true;

		/*try
		{
			JSON.parse(payload);
			return true;
		} catch (e)
		{
			state.data.error = {message: `Payload Must Be A Valid JSON String In ${replyType}.`};
			return false;
		}*/
	};

	const validateUrl = (url, replyType) =>
	{
		if (url.length === 0 || !isUrl(url))
		{
			state.data.error = {message: `Please Enter Valid Url For Button In ${replyType}.`};
			return false;
		}

		return true;
	};

	const validateButton = (buttons, replyType) =>
	{
		for (let v = 0; v < buttons.length; v++)
		{
			let button = buttons[v];

			if (button.title.length === 0)
			{
				state.data.error = {message: `Please Enter Title For All Buttons In ${replyType}.`};
				return false;
			}

			if (button.type === "url")
				return validateUrl(button.content.url, replyType);

			if (button.type === "postback")
				return validatePayload(button.content.payload, replyType);
		}
	};

	const validatePrivateReply = (pm, replyType) =>
	{
		switch (pm.type)
		{
			case 'text':
				if (pm.content === null || pm.content.text.length === 0)
				{
					state.data.error = {message: `Please Enter Text For Text Template In ${replyType}.`};
					return false;
				}
				break;
			case 'attachment':
				if (pm.content.file === null)
				{
					state.data.error = {message: `Please Upload Image For Attachment Template In ${replyType}.`};
					return false;
				}
				break;
			case 'one-time':
				if (pm.content.title.length === 0)
				{
					state.data.error = {message: `Please Enter Title For One-Time Notification In ${replyType}.`};
					return false;
				}

				if (!validatePayload(pm.content.payload, replyType))
					return false;
				break;
			case 'button':
				if (pm.content.text.length === 0)
				{
					state.data.error = {message: `Please Enter Text For Button Template In ${replyType}.`};
					return false;
				}

				if (!validateButton(pm.content.buttons, replyType))
					return false;
				break;
			case 'generic':
				for (let i = 0; i < pm.content.elements.length; i++)
				{
					const element = pm.content.elements[i];

					if (element.title.length === 0)
					{
						state.data.error = {message: "Please Enter Title For Bubble Title."};
						return false;
					}

					if (element.defaultAction.url.length > 0)
					{
						if (!validateUrl(element.defaultAction.url, replyType))
							return false;
					}

					if (element.buttons.length)
					{
						if (!validateButton(element.buttons, replyType))
							return false;
					}

				}
				break;
			default:
				return true;
		}

		if (pm.quickReplies.length > 0)
			return validateQuickReplies(pm.quickReplies, replyType);

		return true;
	};

	const validateQuickReplies = (quickReplies, replyType) =>
	{
		for (let v = 0; v < quickReplies.length; v++)
		{
			let quickReply = quickReplies[v];

			if (quickReply.title.length === 0)
			{
				state.data.error = {message: `Please Enter Title For All Quick Replies In ${replyType}.`};
				return false;
			}

			if (!validatePayload(quickReply.payload, replyType))
				return false;
		}

		console.log("Quick Replies Normal");

		return true;
	};

	const submitForm = () =>
	{
		console.log("Submitting Form!!!!");

		if (!state.submitting)
		{
			state.submitting = true;

			if (validateForm())
			{
				fetch(apiUrl + "/campaign", {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						authorization: user.token,
					},
					body: JSON.stringify(state.data)
				})
				.then(response => response.json())
				.then(data =>
				{
					console.log("This is Response");
					console.log(data);

					state.submitting = false;

					if (!data.success)
					{
						state.data.error = {message: data.error};
						$("html, body").animate({scrollTop: 0}, 400);
					}
					else
						state.submitted = true;

					setState({
						...state,
					});

				}).catch((err) =>
				{
					console.log('錯誤:', err);
				})
			}
			else
			{
				state.submitting = false;

				setState({...state});

				$("html, body").animate({scrollTop: 0}, 400);
			}
		}
	};

	useEffect(() =>
	{
		console.log("This is Form Context");

		if (user)
		{
			if (!state.init && currentPageType === 'Form')
			{
				console.log("========== THIS IS FORM CONTEXT ==========");
				state.data.pageId = id;

				setFacebookDataContextState({
					tableColumns: [
						{
							title: "Post ID",
							field: "id",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
							/*headerFilter: 'input',*/
						},
						{
							title: "Post Content",
							field: "message",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							/*headerFilter: 'input',*/
							validator: 'required'
						},
						{
							title: 'Action',
							align: "center",
							formatter: (cell, formatterParams, onRendered) =>
							{
								let rowData = cell.getRow().getData();

								if (typeof rowData.id !== 'undefined')
									return "<button class='px-5 btn btn-orange" +
									" text-highlight'>Add</button>";
							},
							cellClick: function (e, cell)
							{
								let rowData = cell.getRow().getData();

								$('.modal').modal('hide');

								setFacebookDataContextState({
									modalOpen: false,
									before: null,
									after: null,
									facebookData: [],
								});

								state.data.postId = rowData.id;
								state.data.postTitle = rowData.message;
								state.init = true;

								setState({
									...state
								})
							}
						}
					],
					dataId: id,
					fetchUrl: `/posts`,
					tableOptions:{
						placeholder: "No Post Found.",
					}
				});

				setFormContextState({
					init: true,
				});
			}
		}

	}, [currentPageType, state.init]);


	return (
	<FormContext.Provider
	value={{...state, setFormContextState, fetchFacebookPosts, getFileArray, submitForm, fileUpload}}>
		{props.children}
	</FormContext.Provider>
	);
}
