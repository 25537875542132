import React, {useContext, useEffect, useState} from 'react';
import {PageContext} from "../contexts/PageContext";
import Tabulator from 'tabulator-tables';

export default function Table()
{
	const {tableColumns, tableData, tableOptions} = useContext(PageContext);
	const [state, setState] = useState({
		el: React.createRef(),
		table: null,
		isInit: false,
	});

	useEffect(() =>
	{
		state.table = new Tabulator(state.el, {
			layout: "fitDataStretch",
			data: tableData, //link data to table
			reactiveData: false, //enable data reactivity
			columns: tableColumns, //define table columns
			validationFailed: function (cell, value, validators)
			{
				return cell.cancelEdit();
			},
			...tableOptions
		});

		setState({
			...state,
			isInit: true,
		});

	}, [tableData]);

	return (
	<div className="tableContainer w-100">
		<div className="table-dark" ref={el => (state.el = el)}/>
		{/*{
                state.isInit ? <button className="btn btn-primary text-sm float-right my-4"onClick={()=>{state.table.addRow({status:'inactive'})}}>Add Data</button> : "Testing"
            }*/}

	</div>
	);
}