import React, {createContext, useState, useEffect, useContext} from 'react';
import {useParams} from "react-router-dom";

import {PageContext} from "./PageContext";
import {FormContext} from "./FormContext";
import {MainContext} from "./MainContext";


export const CampaignEditContext = createContext();

export default function CampaignEditContextProvider(props)
{
	const {apiUrl, user, setMainContextState} = useContext(MainContext);
	const {setPageContextState} = useContext(PageContext);
	const {setFormContextState} = useContext(FormContext);

	const {id} = useParams();

	const [state, setState] = useState({
		page: id,
		fetched: false,
		fetching: false,
		scheduledPosts: false,
	});

	const setCampaignEditContextState = (data) =>
	{
		for (var prop in data)
			state[prop] = data[prop];

		setState({
			...state
		});
	};

	useEffect(() =>
	{
		console.log("========== THIS IS CAMPAIGN EDIT CONTEXT ==========");

		if (user)
		{
			if (!state.fetched && !state.fetching)
			{
				state.fetching = true;

				setPageContextState({
					header: "Edit Campaign",

					subtitle: "Edit Auto Reply Campaign For Facebook Post",

					currentPageType:'Form',

					currentPage: 'campaign',

					id: id,

					campaignId: id,

					tableOptions: {},
					tableColumns: [],
					tableData: [],
				});

				fetch(apiUrl + "/campaign/detail", {
					method: 'POST',
					headers: {
						authorization: user.token
					},
					body: JSON.stringify({id: id})
				}).then(response => response.json())    // one extra step
				.then(data =>
				{
					state.fetched = true;
					state.fetching = false;

					setFormContextState({
						data: data.campaign.raw,
						dateLoaded: true,
						init: true,
					});

					setPageContextState({
						id: data.campaign.pageId,
					});

					setMainContextState({
						loading: false
					});

					setState({
						...state
					});

				}).catch((err) =>
				{
					console.log('錯誤:', err);
				})
			}
		}

	}, [state.fetched]);

	return (
	<CampaignEditContext.Provider
	value={{...state, setCampaignEditContextState}}>
		{props.children}
	</CampaignEditContext.Provider>
	);
}
