import React, {useContext} from 'react';
import {PageContext} from "../contexts/PageContext";

export default function PageHeader()
{
    const {header,subtitle}=useContext(PageContext);

    return (
    <div className="page-hero page-container" id="page-hero">
        <div className="p-3">
            <div className="page-title">
                <h5 className="text-highlight weight-700 mb-1">{header}</h5>
                <small className="text-muted">{subtitle}</small>
            </div>
        </div>
    </div>
    );
};