import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import {MainContext} from "../contexts/MainContext";

export default function LoginContainer()
{
    const {apiUrl, user, socket, setMainContextState}=useContext(MainContext);

    const [state, setState]=useState({
        disabled: false,
        popup: null,
        socketSubscribed: false
    });

    const checkPopup=() =>
    {
        const check=setInterval(() =>
        {
            if (!state.popup || state.popup.closed || state.popup.closed === undefined)
            {
                clearInterval(check);
                console.log("Popup Closed");
                setState({
                    ...state,
                    disabled: false,
                    popup: null
                })
            }
        }, 1000)
    };

    // Launches the popup by making a request to the server and then
    // passes along the socket id so it can be used to send back user
    // data to the appropriate socket on the connected client.
    const openPopup=() =>
    {
        //const {provider, socket} = this.props
        const screenLeft=window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const screenTop=window.screenTop !== undefined ? window.screenTop : window.screenY;

        const screenWidth=window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        const screenHeight=window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        const width=800;
        const height=800;

        const systemZoom=screenWidth / window.screen.availWidth;
        const left=(screenWidth - width) / 2 / systemZoom + screenLeft;
        const top=(screenHeight - height) / 2 / systemZoom + screenTop;
        const url=`${apiUrl}/facebook/login?socketId=${socket.id}`;

        console.log(socket.id);

        return window.open(url, '',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
        )
    };

    // Kicks off the processes of opening the popup on the server and listening
    // to the popup. It also disables the login button so the user can not
    // attempt to login to the provider twice.
    const startAuth=(e) =>
    {
        if (!state.disabled)
        {
            e.preventDefault();
            state.popup=openPopup();
            checkPopup();

            setState({
                ...state,
                disabled: 'disabled'
            })
        }
    };

    useEffect(() =>
    {
        console.log("This is Login Container");

        if (state.popup && !state.socketSubscribed)
        {
            socket.on(socket.id, data =>
            {
                state.popup.close();

                console.log(data);

                let mainStates={
                    user: data.user,
                };

                if (data.user.activationId !== null)
                    mainStates.activated=true;

                setMainContextState(mainStates);

                window.localStorage.setItem('user', JSON.stringify(data.user));
            });

            setState({
                ...state,
                socketSubscribed: true,
            });
        }


    }, [state.popup]);

    return (
    (user) ? <Redirect to="/"/> : <div id="main" className="layout-column flex h-100">
        <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="w-50 w-auto-sm mx-auto py-5 text-center">

                    <div className="py-3 logo">
                        <img src="https://assets.facebookautoreply.com/logo.png"/>
                    </div>

                    <h2 className="hidden-folded d-inline text-highlight font-weight-bolder">
                        Facebook Page Auto Reply System
                    </h2>

                    <div className="px-3 py-2 text-center">
                        <button
                        className="btn btn-primary text-md my-4 py-3 px-4 font-weight-500"
                        onClick={(e) =>
                        {
                            startAuth(e)
                        }}>
                            Login With Facebook
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}