import React, {useContext} from 'react';
import {Accordion, Button, Card} from "react-bootstrap";

import {FormContext} from "../contexts/FormContext";
import Element from "./Element";

export default function GenericTemplate(props)
{
    const {data, setFormContextState}=useContext(FormContext);

    const addElement=(e) =>
    {
        const element={
            title: '',
            subtitle: '',
            image: null,
            imageData: null,
            defaultAction: {
                url: '',
                ratio: 'full',
                isMessengerExtension: false
            },
            buttons: [{
                type: 'url',
                title: '',
                content: {
                    url: '',
                    ratio: 'full',
                    isMessengerExtension: false
                }
            }]
        };

        if (props.fallback)
            data.fallbackPM.content.elements.push(element);
        else if (props.type === 'pm')
            data.keywords[props.parent].replies[props.index].pm[props.type].content.elements.push(element);
        else
            data.keywords[props.parent].pm[props.type].content.elements.push(element);

        setFormContextState({
            data: data,
        });

        return false;

    };


    return (
    (function ()
    {
        let id;

        if (props.fallback)
            id=`fallbackAccordion`;
        else if (props.type === 'pm')
            id=`pmAccordion-keyword${props.parent}-reply${props.index}-${props.type}`;
        else
            id=`pmAccordion-keyword${props.parent}-${props.type}`;

        return <>
            <div className="form-group label">
                Bubbles
            </div>
            <Accordion defaultActiveKey="0" id={id} className="replyAccordion">

                {
                    (function ()
                    {
                        let elements;

                        if (props.fallback)
                            elements=data.fallbackPM.content.elements;
                        else if (props.type === 'pm')
                            elements=data.keywords[props.parent].replies[props.index].pm[props.type].content.elements;
                        else
                            elements=data.keywords[props.parent].pm[props.type].content.elements;

                        if (props.fallback)
                            return elements.map((item, i) => <Element key={i} type={props.type}
                                                                      fallback={props.fallback}
                                                                      index={i}/>)
                        else if (props.type === 'pm')
                            return elements.map((item, i) => <Element key={i}
                                                                      keywordParent={props.parent}
                                                                      type={props.type}
                                                                      fallback={props.fallback}
                                                                      parent={props.index}
                                                                      index={i}/>)
                        else
                            return elements.map((item, i) => <Element key={i}
                                                                      type={props.type}
                                                                      fallback={props.fallback}
                                                                      parent={props.parent}
                                                                      index={i}/>)
                    })()
                }
            </Accordion>

            <div className="mb-2 pl-3">
                {
                    (function ()
                    {
                        let elements;

                        if (props.fallback)
                            elements = data.fallbackPM.content.elements;
                        else if (props.type === 'pm')
                            elements = data.keywords[props.parent].replies[props.index].pm[props.type].content.elements;
                        else
                            elements=data.keywords[props.parent].pm[props.type].content.elements;

                        return (elements.length<10) &&
                        <button type="button" className="btn btn-primary" onClick={addElement}>Add Bubble</button>
                    })()
                }
            </div>
        </>
    })()
    );
}