import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import '../assets/scss/app.scss';

import MainContextProvider from "../contexts/MainContext";
import PageContextProvider from "../contexts/PageContext";
import FacebookPageContextProvider from "../contexts/FacebookPageContext";
import FacebookDataContextProvider from "../contexts/FacebookDataContext";
import FacebookCampaignContextProvider from "../contexts/FacebookCampaignContext";

import LoginContainer from './LoginContainer';
import ActivationContainer from "./ActivationContainer";
import Header from "../components/Header";
import CampaignCreateContextProvider from "../contexts/CampaignCreateContext";
import FormContextProvider from "../contexts/FormContext";
import CampaignEditContextProvider from "../contexts/CampaignEditContext";
import Loading from "../components/Loading";
import CampaignDetailContextProvider from "../contexts/CampaignDetailContext";
import AuthComponent from "../components/AuthComponent";
import ListContainer from "./ListContainer";
import FormContainer from "./FormContainer";
import PolicyContainer from "./PolicyContainer";
const App = () =>
{

	return (
	<MainContextProvider>
		<>
			<Router basename="/">
				<Switch>
					<Route exact path="/login" component={LoginContainer}/>
					<Route exact path="/activation" component={ActivationContainer}/>
					<Route exact path="/policy" component={PolicyContainer}/>

					<PageContextProvider>
						<div id="main" className="layout-column flex">
							<Loading/>
							<Header/>
							<FacebookDataContextProvider>
								<FormContextProvider>
									<Route exact path="/"
									       render={(props) =>
									       <FacebookPageContextProvider><AuthComponent
									       component={ListContainer}/></FacebookPageContextProvider>}/>
									<Route exact path="/:id/campaigns"
									       render={(props) =>
									       <FacebookCampaignContextProvider><AuthComponent
									       component={ListContainer}/></FacebookCampaignContextProvider>}/>
									<Route exact path="/campaign/:id"
									       render={(props) =>
									       <CampaignDetailContextProvider><AuthComponent
									       component={ListContainer}/></CampaignDetailContextProvider>}/>
									<Route exact path="/campaign/create/:id"
									       render={(props) =>
									       <CampaignCreateContextProvider><AuthComponent
									       component={FormContainer}/></CampaignCreateContextProvider>}/>
									<Route exact path="/campaign/edit/:id"
									       render={(props) =>
									       <CampaignEditContextProvider><AuthComponent
									       component={FormContainer}/></CampaignEditContextProvider>}/>
								</FormContextProvider>
							</FacebookDataContextProvider>
						</div>
					</PageContextProvider>
				</Switch>
			</Router>
		</>
	</MainContextProvider>
	);
}

export default App;