import React, {useContext} from 'react';
import TemplateButton from "./TemplateButton";
import TinyMCEEditor from "./TinyMCEEditor";

import {FormContext} from "../contexts/FormContext";

export default function ButtonTemplate(props)
{
	const {data, setFormContextState} = useContext(FormContext);

	const onEditorChange = (content) =>
	{
		let buttonContent = null;

		if (props.fallback)
			buttonContent = data.fallbackPM.content;
		else if (props.type === 'pm')
			buttonContent = data.keywords[props.parent].replies[props.index].pm[props.type].content;
		else
			buttonContent = data.keywords[props.parent].pm[props.type].content;

		if (buttonContent === null)
			buttonContent = {text: content, buttons: []};
		else
			buttonContent = {...buttonContent, text: content};

		if (props.fallback)
			data.fallbackPM.content = buttonContent;
		else if (props.type === 'pm')
			data.keywords[props.parent].replies[props.index].pm[props.type].content = buttonContent;
		else
			data.keywords[props.parent].pm[props.type].content = buttonContent;

		return false;
	};

	const getEditorContent = () =>
	{
		let content = null;

		if (props.fallback)
			content = data.fallbackPM.content;
		else if (props.type === 'pm')
			content = data.keywords[props.parent].replies[props.index].pm[props.type].content;
		else
			content = data.keywords[props.parent].pm[props.type].content;

		return (content === null) ? null : content.text;
	};

	const addButton = (e) =>
	{
		const button = {
			type: 'url',
			title: '',
			content: {
				url: '',
				ratio: 'full',
				isMessengerExtension: false
			}
		};

		if (props.fallback)
			data.fallbackPM.content.buttons.push(button);
		else if (props.type === 'pm')
			data.keywords[props.parent].replies[props.index].pm[props.type].content.buttons.push(button);
		else
			data.keywords[props.parent].pm[props.type].content.buttons.push(button);

		setFormContextState({
			data: data
		});

		return false;
	};

	return (
	<>
		<div className="form-group label">
			Text
		</div>
		<div className="form-group">
			<TinyMCEEditor onChange={onEditorChange} content={getEditorContent()}/>
		</div>
		{
			(function ()
			{
				if (props.fallback)
				{
					return data.fallbackPM.content.buttons.map((item, i) =>
					<TemplateButton keywordParent={props.parent} parent={props.index} index={i}
					                key={i}
					                type={props.type}
					                fallback={props.fallback}/>)
				}
				else if (props.type === 'pm')
				{
					return data.keywords[props.parent].replies[props.index].pm[props.type].content.buttons.map((item, i) =>
					<TemplateButton keywordParent={props.parent} parent={props.index} index={i}
					                key={i}
					                type={props.type}
					                fallback={props.fallback}/>)
				}
				else
				{
					return data.keywords[props.parent].pm[props.type].content.buttons.map((item, i) =>
					<TemplateButton parent={props.parent} index={i}
					                key={i}
					                type={props.type}
					                fallback={props.fallback}/>)
				}
			})()
		}

		<div className="form-group">
			{
				(function ()
				{
					let render;

					if (props.fallback)
						render = (data.fallbackPM.content.buttons.length < 3);
					else if (props.type === 'pm')
						render = (data.keywords[props.parent].replies[props.index].pm[props.type].content.buttons.length < 3);
					else
						render = (data.keywords[props.parent].pm[props.type].content.buttons.length < 3);

					return (render) &&
					<button type="button" className="btn btn-green" onClick={addButton}>Add
						Button</button>
				})()
			}
		</div>

	</>)
}