import React, {useContext} from 'react';

import TextTemplate from "./TextTemplate";
import AttachmentTemplate from "./AttachmentTemplate";
import ButtonTemplate from "./ButtonTemplate";
import GenericTemplate from "./GenericTemplate";
import OneTimeTemplate from "./OneTimeTemplate";
import {FormContext} from "../contexts/FormContext";
import QuickReply from "./QuickReply";

export default function PrivateReply(props)
{
	const {data, setFormContextState} = useContext(FormContext);

	const textTemplate = {
		text: ''
	};

	const attachmentTemplate = {
		type: 'image',
		file: null,
		fileType: null,
	};

	const buttonTemplate = {
		text: '',
		buttons: [{
			type: 'url',
			title: '',
			content: {
				url: '',
				ratio: 'full',
				isMessengerExtension: false
			}
		}]
	};

	const oneTimeTemplate = {
		title: '',
		payload: ''
	};

	const genericTemplate = {
		elements: [{
			title: '',
			subtitle: '',
			image: null,
			defaultAction: {
				url: '',
				ratio: 'full',
				isMessengerExtension: false
			},
			buttons: []
		}]
	};

	const setTemplateType = (e) =>
	{
		const type = e.target.value;
		let cache, pm;

		if (props.fallback)
			cache = data.cache;
		else if (props.type === 'pm')
			cache = data.keywords[props.parent].replies[props.index].pm.cache[props.type];
		else
			cache = data.keywords[props.parent].cache[props.type];

		if (props.fallback)
			pm = data.fallbackPM;
		else if (props.type === 'pm')
			pm = data.keywords[props.parent].replies[props.index].pm[props.type];
		else
			pm = data.keywords[props.parent].pm[props.type];


		if (pm.content !== null)
		{
			if (props.fallback)
				data.cache = {type: pm.type, content: pm.content};
			else if (props.type === 'pm')
				data.keywords[props.parent].replies[props.index].pm.cache[props.type] = {type: pm.type, content: pm.content};
			else
				data.keywords[props.parent].cache[props.type] = {type: pm.type, content: pm.content};
		}

		let templateType;

		if (cache.type === type)
			templateType = {...pm, ...cache};
		else
		{
			templateType = {...pm, type: type};

			switch (type)
			{
				case 'text':
					templateType = {...templateType, content: JSON.parse(JSON.stringify(textTemplate))};
					break;
				case 'attachment':
					templateType = {...templateType, content: JSON.parse(JSON.stringify(attachmentTemplate))};
					break;
				case 'one-time':
					templateType = {...templateType, content: JSON.parse(JSON.stringify(oneTimeTemplate))};
					break;
				case 'button':
					templateType = {...templateType, content: JSON.parse(JSON.stringify(buttonTemplate))};
					break;
				case 'generic':
					templateType = {...templateType, content: JSON.parse(JSON.stringify(genericTemplate))};
					break;
				default:
					break;
			}
		}

		if (props.fallback)
			data.fallbackPM = templateType;
		else if (props.type === 'pm')
			data.keywords[props.parent].replies[props.index].pm[props.type] = templateType;
		else
			data.keywords[props.parent].pm[props.type] = templateType;

		setFormContextState({
			data: data
		});

		return false;
	};

	const addQuickReply = (e) =>
	{
		const quickReply = {
			type: 'text',
			title: '',
			payload: '',
			image: null,
		};

		if (props.fallback)
			data.fallbackPM.quickReplies.push(quickReply);
		else if (props.type === 'pm')
			data.keywords[props.parent].replies[props.index].pm[props.type].quickReplies.push(quickReply);
		else
			data.keywords[props.parent].pm[props.type].quickReplies.push(quickReply);

		setFormContextState({
			data: data
		});

		return false;
	};

	return (
	<>
		{
			(!props.fallback) && <h5 className="form-group">{props.title}</h5>
		}

		<div className="form-group label">
			Type
		</div>
		<div className="form-group">
			{
				(function ()
				{
					let type;

					if (props.fallback)
						type = data.fallbackPM.type;
					else if (props.type === 'pm')
						type = data.keywords[props.parent].replies[props.index].pm[props.type].type;
					else
						type = data.keywords[props.parent].pm[props.type].type;

					return <select className="custom-select" name="templateType"
					               onChange={setTemplateType}
					               value={type}>
						<option value="text">Text Template</option>
						<option value="attachment">Attachment Template</option>
						<option value="one-time">One Time Notification Template</option>
						<option value="button">Button Template</option>
						<option value="generic">Generic Template</option>
					</select>

				})()
			}
		</div>

		<div
		className={(props.fallback) ? 'templateContainer fallback' : `templateContainer ${props.type}`}>
			{
				(function ()
				{
					let type = null;

					if (props.fallback)
						type = data.fallbackPM.type;
					else if (props.type === 'pm')
						type = data.keywords[props.parent].replies[props.index].pm[props.type].type;
					else
						type = data.keywords[props.parent].pm[props.type].type;

					switch (type)
					{
						case "text":
							return <TextTemplate parent={props.parent} index={props.index}
							                     type={props.type} fallback={props.fallback}/>;
						case "attachment":
							return <AttachmentTemplate parent={props.parent} index={props.index}
							                           type={props.type}
							                           fallback={props.fallback}/>;
						case "one-time":
							return <OneTimeTemplate parent={props.parent} index={props.index}
							                        type={props.type}
							                        fallback={props.fallback}/>;
						case "button":
							return <ButtonTemplate parent={props.parent} index={props.index}
							                       type={props.type} fallback={props.fallback}/>;
						case "generic":
							return <GenericTemplate parent={props.parent} index={props.index}
							                        type={props.type} fallback={props.fallback}/>;
						default:
							return null;

					}
				})()
			}

			{
				(function ()
				{
					if (props.fallback)
					{
						return data.fallbackPM.quickReplies.map((item, i) =>
						<QuickReply parent={props.parent} index={i}
						            type={props.type} fallback={props.fallback} key={i}/>)
					}
					else if (props.type === 'pm')
					{
						return data.keywords[props.parent].replies[props.index].pm[props.type].quickReplies.map((item, i) =>
						<QuickReply keywordParent={props.parent} parent={props.index} index={i}
						            type={props.type} fallback={props.fallback} key={i}/>)
					}
					else
					{
						return data.keywords[props.parent].pm[props.type].quickReplies.map((item, i) =>
						<QuickReply parent={props.parent} index={i}
						            type={props.type} fallback={props.fallback} key={i}/>)
					}
				})()
			}
		</div>

		<div className="form-group">
			{
				(function ()
				{
					let render;

					if (props.fallback)
						render = (data.fallbackPM.quickReplies.length < 13);
					else if (props.type === 'pm')
						render = (data.keywords[props.parent].replies[props.index].pm[props.type].quickReplies.length < 13);
					else
						render = (data.keywords[props.parent].pm[props.type].quickReplies.length < 13);

					return (render) &&
					<button type="button" className="btn btn-green" onClick={addQuickReply}>Add
						Quick Reply</button>
				})()
			}
		</div>
	</>
	)
}