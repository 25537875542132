import React from 'react';

export default function Error(props)
{
	return (
	(props.error) && <div className="page-hero page-container" id="page-hero">
		<div className="pt-4 px-3">
			<div className={`alert btn-red alert-dismissible fade `+((props.error) && `show`)} role="alert">
				<div className="d-flex">
					<div className="mx-3 font-weight-bold">{props.error.message}</div>
				</div>
			</div>
		</div>
	</div>
	);
};