import React, {createContext, useState, useEffect, useContext} from 'react';
import {Redirect} from "react-router-dom";
import $ from 'jquery';

import {PageContext} from "./PageContext";
import {MainContext} from "./MainContext";
import {FacebookDataContext} from "./FacebookDataContext";


export const FacebookPageContext = createContext();

export default function FacebookPageContextProvider(props)
{
	const {apiUrl, user, setMainContextState} = useContext(MainContext);

	const {setPageContextState} = useContext(PageContext);

	const {facebookData, setFacebookDataContextState} = useContext(FacebookDataContext);

	const [state, setState] = useState({
		pages: [],
		fetching: false,
	});

	const setFacebookPageContextState = (data) =>
	{
		for (var prop in data)
			state[prop] = data[prop];

		setState({
			...state
		});
	};

	const createFacebookPage = (data) =>
	{
		fetch(apiUrl + "/page", {
			method: 'PUT',
			headers: {
				'content-type': 'application/json',
				authorization: user.token
			},
			body: JSON.stringify(data),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			setFacebookDataContextState({
				modalOpen: false,
				before: null,
				after: null,
				facebookData: [],
			});

			setState({
				...state,
				pages: data.pages
			});

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	};

	const removeFacebookPage = (data) =>
	{
		fetch(apiUrl + "/page", {
			method: 'DELETE',
			headers: {
				'content-type': 'application/json',
				authorization: user.token
			},
			body: JSON.stringify(data),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			setState({
				...state,
				pages: data.pages
			});

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	}

	const toCampaignPage = (data) =>
	{
		const path = `/react/${data}/campaigns`;
		return <Redirect to={path}/>;
	}

	useEffect(() =>
	{
		console.log("========== THIS IS FACEBOOK PAGE CONTEXT ==========");

		if (user)
		{
			setPageContextState({
				tableData: state.pages,
			});

			if (!state.fetched && !state.fetching)
			{
				setState({
					...state,
					fetching: true
				});

				setPageContextState({
					header: "Facebook Pages",

					subtitle: "Subscribe Pages You Wish To Add Post Campaign",

					createButtonName: "Add Facebook Page",

					currentPage: 'page',

					detail: null,

					tableColumns: [
						{
							title: "Page ID",
							field: "pageId",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required'
						},
						{
							title: "Page Name",
							field: "name",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required'
						},
						{
							title: "Created At",
							field: "createdAt",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
						},
						{
							title: "Updated At",
							field: "updatedAt",
							sorter: "date",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
						},
						{
							title: 'Action',
							formatter: (cell, formatterParams, onRendered) =>
							{
								let rowData = cell.getRow().getData();

								if (typeof rowData.pageId !== 'undefined')
									return `
									<a href="/campaign/create/${rowData.pageId}">
										<button id='create' class='btn btn-primary'>Create Campaign</button>
									</a>
									<a href="/${rowData.pageId}/campaigns">
										<button id='view' class='btn btn-green'>View Campaigns</button>
									</a>
									<button id='delete' class='btn btn-red'>Delete</button>`;
							},
							align: "center",
							cellClick: function (e, cell)
							{
								let rowData = cell.getRow().getData();
								rowData.userId = user.id;

								switch (e.target.id)
								{
									case `create`:
										break;
									case `view`:
										//console.log(appUrl+`/react/${rowData.pageId}/campaigns`);

										if (window.location.href.charAt(window.location.href.length - 1) === '/')
											window.location.href = window.location.href + `${rowData.pageId}/campaigns`;
										else
											window.location.href = window.location.href + `/${rowData.pageId}/campaigns`;
										break;
									case `delete`:
										if (window.confirm("Confirm To Delete Record?"))
										{
											removeFacebookPage(rowData);
										}
										break;

								}
							}
						},
					],

					tableData: state.pages,

					tableOptions: {
						placeholder: "No Pages",
						rowFormatter: function (row)
						{
							row.getElement().classList.add("table-dark"); //mark rows with age greater than or equal to 18 as successful;
						},
					}
				});

				setFacebookDataContextState({
					tableColumns: [
						{
							title: "Page ID",
							field: "id",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required',
							/*headerFilter: 'input',*/
						},
						{
							title: "Page Name",
							field: "name",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							/*headerFilter: 'input',*/
							validator: 'required'
						},
						{
							title: 'Action',
							align: "center",
							formatter: (cell, formatterParams, onRendered) =>
							{
								let rowData = cell.getRow().getData();

								if (typeof rowData.id !== 'undefined')
									return "<button class='px-5 btn btn-orange" +
									" text-highlight'>Add</button>";
							},
							cellClick: function (e, cell)
							{
								let rowData = cell.getRow().getData();
								rowData.userId = user.id;

								$('.modal').modal('hide');

								createFacebookPage(rowData);
							}
						}
					],
					tableOptions:{
						placeholder: "No Page Found.",
					},
					fetchUrl: '/pages',
				});

				fetch(apiUrl + "/pages", {
					headers: {
						authorization: user.token
					}
				}).then(response => response.json())    // one extra step
				.then(data =>
				{
					state.fetching = false;
					state.fetched = true;

					setState({
						...state,
						pages: data.pages,
					});

					setMainContextState({
						loading: false,
						activated: data.activated
					});

				}).catch((err) =>
				{
					console.log('錯誤:', err);
				})
			}
		}

	}, [state.pages, facebookData]);

	return (
	<FacebookPageContext.Provider
	value={{...state, setFacebookPageContextState}}>
		{props.children}
	</FacebookPageContext.Provider>
	);
}
