import React, {useContext, useEffect, useState} from 'react';
import Dropzone from 'react-dropzone-uploader'

import UrlButton from "./UrlButton";
import TemplateButton from "./TemplateButton";
import {FormContext} from "../contexts/FormContext";
import {Accordion, Button, Card} from "react-bootstrap";
import isUrl from 'is-url';
import mime from "mime-types";

export default function Element(props)
{
	const {data, setFormContextState, getFileArray, fileUpload} = useContext(FormContext);
	const [state, setState] = useState({
		image: null,
		fetched: false,
		fetching: false
	});

	const setContent = (e) =>
	{
		const field = e.target.dataset.field;

		if (props.fallback)
			data.fallbackPM.content.elements[props.index][field] = e.target.value;
		else if (props.type === 'pm')
			data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.index][field] = e.target.value;
		else
			data.keywords[props.parent].pm[props.type].content.elements[props.index][field] = e.target.value;

		setFormContextState({
			data: data,
		});

		return false;
	};

	const onFileChange = async ({meta, file}, status) =>
	{
		if (status !== 'removed' && status !== 'done')
			return false;

		let image = (status !== 'removed') ? file : null;

		if (image !== null)
		{
			image = await getFileArray(file);

			fileUpload(image, file.type, (url) =>
			{
				if (props.fallback)
					data.fallbackPM.content.elements[props.index].image = url;
				else if (props.type === 'pm')
					data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.index].image = url;
				else
					data.keywords[props.parent].pm[props.type].content.elements[props.index].image = url;

			})
		}
		else
		{
			if (props.fallback)
				data.fallbackPM.content.elements[props.index].image = image;
			else if (props.type === 'pm')
				data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.index].image = image;
			else
				data.keywords[props.parent].pm[props.type].content.elements[props.index].image = image;
		}

		return false;
	};

	const addButton = (e) =>
	{
		const button = {
			type: 'url',
			title: '',
			content: {
				url: '',
				ratio: 'full',
				isMessengerExtension: false
			}
		};

		if (props.fallback)
			data.fallbackPM.content.elements[props.index].buttons.push(button);
		else if (props.type === 'pm')
			data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.index].buttons.push(button);
		else
			data.keywords[props.parent].pm[props.type].content.elements[props.index].buttons.push(button);

		setFormContextState({
			data: data
		});

		return false;
	};

	const removeBubble = (e) =>
	{
		if (props.fallback)
			data.fallbackPM.content.elements.splice(e.target.dataset.index, 1);
		else if (props.type === 'pm')
			data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements.splice(e.target.dataset.index, 1);
		else
			data.keywords[props.parent].pm[props.type].content.elements.splice(e.target.dataset.index, 1);

		setFormContextState({
			data: data,
		})
	};

	useEffect(() =>
	{
		let image = null;

		if (props.fallback)
			image = data.fallbackPM.content.elements[props.index].image;
		else if (props.type === 'pm')
			image = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.index].image;
		else
			image = data.keywords[props.parent].pm[props.type].content.elements[props.index].image;

		if (!state.fetched && !state.fetching)
		{
			state.fetching = true;
			if (image !== null && isUrl(image))
			{
				fetch(image, {
					method: 'get',
					responseType: 'arraybuffer'
				}).then(res =>
				{
					return res.arrayBuffer();
				}).then(arraybuffer =>
				{
					state.image = new File([arraybuffer], image.substring(image.lastIndexOf('/') + 1), {type: mime.lookup(image)});

					setState({
						...state,
						fetched: true,
						fetching: false
					});
				})
			}
		}
	}, [state.fetched]);

	return (
	<>
		<Card>
			<Accordion.Toggle as={Button} variant="link" eventKey={props.index}>
				<Card.Header>
					<h5 className="mb-0 d-inline-block">
						Bubble #{props.index + 1}
					</h5>
				</Card.Header>
				{
					(function ()
					{
						let render;

						if (props.fallback)
							render = (data.fallbackPM.content.elements.length > 1);
						else if (props.type === 'pm')
							render = (data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements.length > 1);
						else
							render = (data.keywords[props.parent].pm[props.type].content.elements.length > 1);

						return (render) && <div className="close d-inline-block pb-2 pt-1 pl-2 pr-2"
						                        onClick={removeBubble}>
							<span aria-hidden="true" data-index={props.index}>&times;</span>
						</div>
					})()
				}

			</Accordion.Toggle>
			<Accordion.Collapse eventKey={props.index} data-index={props.index}>
				<Card.Body>
					<div className="form-group label">Title</div>

					<div className="form-group">
						{
							(function ()
							{
								let title;

								if (props.fallback)
									title = data.fallbackPM.content.elements[props.index].title;
								else if (props.type === 'pm')
									title = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.index].title;
								else
									title = data.keywords[props.parent].pm[props.type].content.elements[props.index].title;

								return <input type="text" className="form-control title"
								              data-field="title"
								              onChange={setContent}
								              value={title}
								              placeholder="Enter Button Title"/>
							})()
						}
					</div>

					<div className="form-group label">Sub Title</div>

					<div className="form-group">
						{
							(function ()
							{
								let subtitle;

								if (props.fallback)
									subtitle = data.fallbackPM.content.elements[props.index].subtitle;
								else if (props.type === 'pm')
									subtitle = data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.index].subtitle;
								else
									subtitle = data.keywords[props.parent].pm[props.type].content.elements[props.index].subtitle;

								return <input type="text" className="form-control title"
								              data-field="subtitle"
								              onChange={setContent}
								              value={subtitle}
								              placeholder="Enter Button Sub Title"/>
							})()
						}
					</div>


					<div className="form-group label">Image</div>

					<div className="form-group">
						{
							(state.image) ? <Dropzone
							onChangeStatus={onFileChange}
							multiple={false}
							maxFiles="1"
							initialFiles={[state.image]}
							/> : <Dropzone
							onChangeStatus={onFileChange}
							multiple={false}
							maxFiles="1"
							/>
						}
					</div>

					<hr/>

					<h5 className="form-group">Default Action</h5>

					{
						(function ()
						{
							if (props.fallback)
							{
								return <UrlButton bubbleParent={props.index} type={props.type}
								                  defaultAction={true}
								                  isElement={true}
								                  fallback={props.fallback}/>
							}
							else if (props.type === 'pm')
							{
								return <UrlButton keywordParent={props.keywordParent}
								                  parent={props.parent} bubbleParent={props.index}
								                  type={props.type}
								                  defaultAction={true}
								                  isElement={true}
								                  fallback={props.fallback}/>
							}
							else
							{
								return <UrlButton parent={props.parent} bubbleParent={props.index}
								                  type={props.type}
								                  defaultAction={true}
								                  isElement={true}
								                  fallback={props.fallback}/>
							}
						})()
					}

					{
						(function ()
						{
							if (props.fallback)
							{
								return data.fallbackPM.content.elements[props.index].buttons.map((item, i) =>
								<TemplateButton bubbleParent={props.index} index={i}
								                key={i}
								                type={props.type}
								                isElement={true}
								                fallback={props.fallback}/>)
							}
							else if (props.type === 'pm')
							{
								return data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.index].buttons.map((item, i) =>
								<TemplateButton keywordParent={props.keywordParent}
								                parent={props.parent} bubbleParent={props.index}
								                index={i}
								                key={i}
								                type={props.type}
								                isElement={true}
								                fallback={props.fallback}/>)
							}
							else
							{
								return data.keywords[props.parent].pm[props.type].content.elements[props.index].buttons.map((item, i) =>
								<TemplateButton parent={props.parent} bubbleParent={props.index}
								                index={i}
								                key={i}
								                type={props.type}
								                isElement={true}
								                fallback={props.fallback}/>)
							}
						})()
					}

					<div className="form-group">
						{
							(function ()
							{
								let render;

								if (props.fallback)
									render = (data.fallbackPM.content.elements[props.index].buttons.length < 3);
								else if (props.type === 'pm')
									render = (data.keywords[props.keywordParent].replies[props.parent].pm[props.type].content.elements[props.index].buttons.length < 3);
								else
									render = (data.keywords[props.parent].pm[props.type].content.elements[props.index].buttons.length < 3);

								return (render) &&
								<button type="button" className="btn btn-green"
								        onClick={addButton}>Add
									Button</button>
							})()
						}
					</div>
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	</>)
}